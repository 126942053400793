import React from 'react'

import MetaTag from '../../Conteiners/MetaTag'
import MetaReact from '../../Conteiners/MetaReact'
import Header from '../../Conteiners/Header'

export const Contact = ({ contactService }) => {

  return (

        <div class="Content-st">

              <MetaTag         
                  title = "Galería"
                  img = "icon/ms-icon-144x144.png"
                  desc = "This blog was created with the purpose of sharing my experience during the development in different projects, we know that in each project we learn something new so my have the certainty that this experience is worth being shared."
                  autor = "Isaac G Banda"
              />


              <div class="hfeed site" id="page">

                   <Header page = "Contact"/>
                   
                   <div id="content" class="site-content">
                   <div class="ast-container">
                   <div id="primary" class="content-area primary">

                    <main id="main" class="s">
                        
                        <article class="post-11 page type-page status-publish ast-article-single" id="post-11" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                            <header class="entry-header ast-no-title ast-header-without-markup"></header> 

                            <div class="entry-content clear" itemprop="text">

                               <div data-elementor-type="wp-post" data-elementor-id="11" class="elementor elementor-11">
                   
                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-69a61f19 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="69a61f19" data-element_type="section" data-settings='{"background_background":"classic"}'>

                                                    <div class="elementor-background-overlay"></div>
                                                    <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15912662" data-id="15912662" data-element_type="column">
                                                    <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-8ed6ae0 elementor-widget elementor-widget-heading" data-id="8ed6ae0" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                    <h1 class="elementor-heading-title elementor-size-default">Contacto</h1> </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7dd1dc0b" data-id="7dd1dc0b" data-element_type="column">
                                                    <div class="elementor-widget-wrap">
                                                    </div>
                                                    </div>
                                                    </div>
                                                    
                                    </section>
                                    

<section class="elementor-section elementor-top-section elementor-element elementor-element-a5c472b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a5c472b" data-element_type="section" data-settings='{"background_background":"classic"}'>
<div class="elementor-container elementor-column-gap-default">
<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6430ac6" data-id="6430ac6" data-element_type="column">
<div class="elementor-widget-wrap elementor-element-populated">
<div class="elementor-element elementor-element-3bd0449 elementor-widget elementor-widget-uael-google-map" data-id="3bd0449" data-element_type="widget" data-settings='{"option_streeview":"yes","type_control":"yes","zoom_control":"yes","fullscreen_control":"yes"}' data-widget_type="uael-google-map.default">
<div class="elementor-widget-container">
<div class="uael-google-map-wrap">
<div id="uael-google-map-3bd0449" class="uael-google-map" data-map_options='{"zoom":15,"maptypeid":"roadmap","maptypecontrol":true,"streetviewcontrol":true,"zoomcontrol":true,"fullscreencontrol":true,"gesturehandling":true}' data-cluster="yes" data-cluster-attr="[]" data-max-width="250" data-locations='[["51.474088","-0.175179",true,"36 battersea square, london","","","","","iw_open"]]' data-animate="drop" data-auto-center="center"></div>

</div>
</div>
</div>
</div>
</div>
<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b111231" data-id="b111231" data-element_type="column">
<div class="elementor-widget-wrap elementor-element-populated">
<div class="elementor-element elementor-element-ace4127 uael-heading-align-left uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading" data-id="ace4127" data-element_type="widget" data-widget_type="uael-advanced-heading.default">
<div class="elementor-widget-container">
<div class="uael-module-content uael-heading-wrapper">
<h2 class="uael-heading">
<span class="uael-heading-text elementor-inline-editing uael-size--default" data-elementor-setting-key="heading_title" data-elementor-inline-editing-toolbar="basic">Say "Hello"</span>
</h2>
<div class="uael-subheading elementor-inline-editing" data-elementor-setting-key="heading_description" data-elementor-inline-editing-toolbar="advanced">
Consectetur adipisicing elit, send do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ullamco. </div>
</div>
</div>
</div>
<section class="elementor-section elementor-inner-section elementor-element elementor-element-4887add elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4887add" data-element_type="section">
<div class="elementor-container elementor-column-gap-default">
<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-005095e" data-id="005095e" data-element_type="column">
<div class="elementor-widget-wrap elementor-element-populated">
<div class="elementor-element elementor-element-aba54d4 elementor-widget elementor-widget-heading" data-id="aba54d4" data-element_type="widget" data-widget_type="heading.default">
<div class="elementor-widget-container">
<h4 class="elementor-heading-title elementor-size-default">Get in Touch</h4> </div>
</div>
<div class="elementor-element elementor-element-fa9ce62 elementor-align-left elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="fa9ce62" data-element_type="widget" data-widget_type="icon-list.default">
<div class="elementor-widget-container">
<ul class="elementor-icon-list-items">
<li class="elementor-icon-list-item">
<a href="#">
<span class="elementor-icon-list-icon">
<i aria-hidden="true" class="far fa-envelope"></i> </span>
<span class="elementor-icon-list-text"><span class="__cf_email__" data-cfemail="f89d80999588949db896978a9d889481d69b9795">[email�&nbsp;protected]</span></span>
</a>
</li>
<li class="elementor-icon-list-item">
<a href="#">
<span class="elementor-icon-list-icon">
<i aria-hidden="true" class="fas fa-phone-alt"></i> </span>
<span class="elementor-icon-list-text"> 01234-56789</span>
</a>
</li>
<li class="elementor-icon-list-item">
<a href="#">
<span class="elementor-icon-list-icon">
<i aria-hidden="true" class="fas fa-map-marker-alt"></i> </span>
<span class="elementor-icon-list-text">36 Battersea Square, London</span>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f46a578" data-id="f46a578" data-element_type="column">
<div class="elementor-widget-wrap elementor-element-populated">
<div class="elementor-element elementor-element-f9b2d07 elementor-widget elementor-widget-heading" data-id="f9b2d07" data-element_type="widget" data-widget_type="heading.default">
<div class="elementor-widget-container">
<h4 class="elementor-heading-title elementor-size-default">Connect</h4> </div>
</div>
<div class="elementor-element elementor-element-36fe5b8 elementor-shape-circle e-grid-align-left e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons" data-id="36fe5b8" data-element_type="widget" data-widget_type="social-icons.default">
<div class="elementor-widget-container">
<div class="elementor-social-icons-wrapper elementor-grid">
<span class="elementor-grid-item">
<a class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-repeater-item-f435b6f" href="#" target="_blank">
<span class="elementor-screen-only">Facebook-f</span>
<i class="fab fa-facebook-f"></i> </a>
</span>
<span class="elementor-grid-item">
<a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-828753a" href="#" target="_blank">
<span class="elementor-screen-only">Instagram</span>
<i class="fab fa-instagram"></i> </a>
</span>
<span class="elementor-grid-item">
<a class="elementor-icon elementor-social-icon elementor-social-icon-dribbble elementor-repeater-item-c864c92" href="#" target="_blank">
<span class="elementor-screen-only">Dribbble</span>
<i class="fab fa-dribbble"></i> </a>
</span>
<span class="elementor-grid-item">
<a class="elementor-icon elementor-social-icon elementor-social-icon-behance elementor-repeater-item-4d9e17c" href="#" target="_blank">
<span class="elementor-screen-only">Behance</span>
<i class="fab fa-behance"></i> </a>
</span>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
</div>
</div>
</section>


                                   

                              </div>

                             </div>

                    </article>
                    </main>
                    </div>
                    </div> 
                    </div>

              </div>

        </div>

)};

export default Contact



