import React from 'react'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import MetaTag from '../../Conteiners/MetaReact'

import artworks from '../../../assets/json/artworks.json'

import { FormattedMessage,useIntl } from "react-intl";

import { calculateDiscountedPrice } from '../../../Helpers/MathHelper';

class Store extends React.Component {

  constructor(props) {
    super(props);

        // Extracting the search string from the location object
        const queryString = this.props.location.search;

        // Parsing the search string to get the query parameters
        const searchParams = new URLSearchParams(queryString);
    
        // Getting the value of the 'title' parameter
        const category = searchParams.get('category');

    const originalPieces = artworks.pieces; // Using state_arts instead of artworks directly
    const shuffledPieces = this.filterAndOrderByCategory(this.shuffleArray([...originalPieces]), category);

    this.state_arts_random = {
      shuffledPieces: shuffledPieces,
      originalPieces: originalPieces,
  };

    this.state_arts = artworks;

    this.state = {
      isModalOpen: false,
      selectedProduct: {
        imageData: 'image-url.jpg',
        productName: 'Product Name',
        productDescription: 'Product Description',
        productPrice: '$10.00'
      }
    };
   // this.shuffleAndSetPieces();
    
  }
  shuffleAndSetPieces() {
    const originalPieces = this.state_arts.pieces; // Using state_arts instead of artworks directly
    const shuffledPieces = this.shuffleArray([...originalPieces]);
    this.state_arts_random({ shuffledPieces, originalPieces });
}

shuffleArray(array) {
  console.log("Shuffling array...");
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      console.log(j);
  }

  return shuffledArray;
}
 filterAndOrderByCategory(artworks, category) {
  // Separate artworks into two groups: matching category and other categories
  const matchingCategoryArtworks = [];
  const otherCategoryArtworks = [];

  artworks.forEach(piece => {
    if (piece.category === category) {
      matchingCategoryArtworks.push(piece);
    } else {
      otherCategoryArtworks.push(piece);
    }
  });

  // Concatenate the two groups while keeping the original order
  const filteredAndOrderedArtworks = matchingCategoryArtworks.concat(otherCategoryArtworks);

  return filteredAndOrderedArtworks;
}

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    
  }
  
    render(){
      return (

        <div class="Content-st">

        <div class="hfeed site" id="page">

             <MetaTag title = "header.store" pages = "OK" desc = 'store.section.offert.desc'/>
             
             <div id="content" class="site-content">
             <div class="ast-container">
             <div id="primary" class="content-area primary">

              <main id="main" class="s">
                  
                  <article class="post-11 page type-page status-publish ast-article-single" id="post-11" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                      <header class="entry-header ast-no-title ast-header-without-markup"></header> 

                      <div class="entry-content clear" itemprop="text">

                         <div data-elementor-type="wp-post" data-elementor-id="11" class="elementor elementor-11">
             
                              <section class="elementor-section elementor-top-section elementor-element elementor-element-69a61f19 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="69a61f19" data-element_type="section" data-settings='{"background_background":"classic"}'>

                                        <div class="elementor-background-overlay"></div>
                                        <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15912662" data-id="15912662" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-8ed6ae0 elementor-widget elementor-widget-heading" data-id="8ed6ae0" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                        <h1 class="elementor-heading-title elementor-size-default"> <FormattedMessage id="store.section.title" /> </h1> </div>
                                        </div>
                                        </div>
                                        </div>
                                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7dd1dc0b" data-id="7dd1dc0b" data-element_type="column">
                                        <div class="elementor-widget-wrap">
                                        </div>
                                        </div>
                                        </div>

                              </section>

                              <section class="elementor-section elementor-inner-section elementor-element elementor-element-9097030 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9097030" data-element_type="section">
                                    <div class="">
                                       
                                        <div class="" data-id="dc4bc25" data-element_type="column">
                                            <div class="">
                                            <div class="">
                                                            {/* Insert Category  */}

                                                  
                                                          <h3 class=" color-about-store-3 " data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"> <FormattedMessage id="store.section.sub.title" /> </h3>
                                                          <p class="uael-infobox-title-prefix elementor-inline-editing color-about-store-4  p-0 m-0" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">

                                                               <FormattedMessage id="store.section.offert.desc" />

                                                          </p>

                                                  </div>
                                            </div>
                                            
                                        </div>

                                       
                                      
                                    </div>
                                    
                                </section>

        <section class="elementor-section elementor-inner-section elementor-element elementor-element-9097030 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9097030" data-element_type="section">
                
             <div class="elementor-container elementor-column-gap-default">
                
              <div class="boxes-con">                   

                 {this.state_arts_random.shuffledPieces.map((piece, index) => (
         
  
                  <div class="boxes elementor-animation-float">
                     
               
                     {/* <ShowDetails  piece={piece} id={index}/> */}

                     <Link to={'/Details?title=' + piece.title.split(' ').join('-')} className="" class="remove-link card">

                      <div class="uael-module-content uael-imgicon-wrap">
                            <div class="uael-image" itemscope="" itemtype="http://schema.org/ImageObject">
                                <div class="uael-image-content">
                  
                                    <img class="image-border-top" loading="lazy" decoding="async" src={piece.img1} alt="" />
                          
                                </div>
                            </div>
                       </div>

                       <div class="card_content">
    <div class="card_title color-about-store-2">{piece.title}</div>
    <div class="card_desc">

                                    {piece.offer === 0 ? 
                                        <span></span> 
                                        :
                                        piece.price != 'SOLD'? 
                                          <p class="uael-infobox-title-prefix elementor-inline-editing remove-borders " data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                                           
                                             <FormattedMessage id="store.price.original" /> <span class="middle-line">${piece.price}</span> 
                                             
                                             </p>
                                        :<span></span> 
                                    } 

                                    {piece.price != 'SOLD'? 
                                    
                                           <b class="uael-infobox-title-prefix elementor-inline-editing remove-borders pb-0" >
                                              {piece.offer === 0 ? 
                                                <FormattedMessage id="store.price.original"/> 
                                                :<FormattedMessage id="store.price.offer" />
                                              }
                                      
                                                ${calculateDiscountedPrice(piece.price, piece.offer)}
                                             
                                             </b>
                                        
                                          :  <b class="red-percent" > {piece.price}</b>
                                        
                                    }

                                  &nbsp;&nbsp;
                                   {piece.offer === 0 ? 
                                        <span></span> 
                                        :
                                        piece.price != 'SOLD'? 
                                        <span class="red-percent">
                                          
                                          -{piece.offer}% &nbsp;
                                          
                                           </span>
                                           :    <span></span> 
                                    }
    </div>
  </div>
  <div class="card_bottom_shadow"></div>



                        </Link>
                  </div>
        
              

          
   
        ))}


        </div>
              </div>
              </section>
                        </div>

                       </div>

              </article>
              </main>
              </div>
              </div> 
              </div>

        </div>

  </div>
)
    }
  
}

export default Store


