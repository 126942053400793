import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation_lottie/loading _2.json'
import Header from '../../Components/Conteiners/Header' 

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class Loading extends React.Component {

  render(){

    return(
      <div className="Content-st">

      <div>

      <Header first = "Contact" second = "Us" comment = "Do you need help in you project? "/>
      
        <div class ="row mb-4 justify-content-center">
                <div class ="col-12 col-lg-5 col-md-6 col-sm-12 p-4 mb-5 mt-5 my-auto">
             <Lottie options={defaultOptions}/>
             <h1 class = "text-center "> Please wait! </h1>
             <p class = "text-gray text-center ">Send email...</p>
             </div>
         </div>

      </div>

     </div>
    )
  }
}

export default Loading
