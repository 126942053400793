import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import {getLanguage} from '../../../src/Helpers/LenguajesHelper'
import langSpanish from '../../../src/lang/es.json';
import langEnglish from '../../../src/lang/en.json';

const locale = navigator.language;

const setIMG = "images/artist-intro.webp";

const setWebSite  = "https://itzaartsgallery.com";

const setWebSiteName  = "Itza Art Gallery";

const lang =  getLanguage(locale) === 'Spanish' ? langSpanish : langEnglish;

class MetaReact extends Component {
  render() {
  

    return (
        <div className="wrapper">
          
          <Helmet>
          
          <title> Itza Art's -  {this.props.pages != null ?  lang[this.props.title] : this.props.title} </title>
         
          <meta name="title" content= {this.props.pages != null ?  lang[this.props.title] : this.props.title}/>
         
          <meta name="description" content={this.props.desc != null ? lang[this.props.desc] : lang['meta.desc']}/>

          <meta itemprop="name"  content= {this.props.pages != null ?  lang[this.props.title] : this.props.title}/>
          <meta itemprop="description" content={this.props.desc != null ? lang[this.props.desc] : lang['meta.desc']}/>
          <meta itemprop="image" content={this.props.img != null ? this.props.img : setIMG} ></meta>
       
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content= {this.props.pages != null ?  lang[this.props.title] : this.props.title} />
          <meta property="og:description" content={this.props.desc != null ? lang[this.props.desc] : lang['meta.desc']} />
          <meta property="og:url" content={setWebSite + window.location.pathname + window.location.search}/>
          <meta property="og:site_name" content={setWebSiteName} />
          <meta property="og:image" content={this.props.img != null ? this.props.img : setIMG} />
          <meta property="og:image:type" content="image/webp" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={setWebSite} />
          <meta name="twitter:title" content= {this.props.pages != null ?  lang[this.props.title] : this.props.title}/>
          <meta name="twitter:description"  content={this.props.desc != null ? lang[this.props.desc] : lang['meta.desc']} />
          <meta name="twitter:image" content={this.props.img != null ? this.props.img : setIMG} />

          <meta property="linkedin:title" content= {this.props.pages != null ?  lang[this.props.title] : this.props.title} />
          <meta property="linkedin:description"  content={this.props.desc != null ? lang[this.props.desc] : lang['meta.desc']} />
          <meta property="linkedin:image" content={this.props.img != null ? this.props.img : setIMG} />
          <meta property="linkedin:url" content={setWebSite + window.location.pathname + window.location.search}/> 
        
          <meta property="pinterest:title" content= {this.props.pages != null ?  lang[this.props.title] : this.props.title} />
          <meta property="pinterest:description"  content={this.props.desc != null ? lang[this.props.desc] : lang['meta.desc']} />
          <meta property="pinterest:image" content={this.props.img != null ? this.props.img : setIMG} />
          
        
          <meta property="whatsapp:text" content={this.props.desc != null ? lang[this.props.desc] : lang['meta.desc']} />
          <meta property="whatsapp:image" content={this.props.img != null ? this.props.img : setIMG}/>
          
          <meta name="keywords" content={lang['meta.keyword']} />
          <meta name="author" content="Itza Morales"/> 

          </Helmet>

          
        </div>
      )
  }
}


export default MetaReact;