import React, { Component } from 'react'

var logo = require('../../assets/Image/logo1.png')
var imageIn = require('../../assets/Image/linkedin.svg')
var imageGit = require('../../assets/Image/github.svg')
var imageInst = require('../../assets/Image/instagram-sketched.svg')

class HeaderAbout extends React.Component {

  render(){

    const style2 = {
      position: 'relative',
      width: '100%',
      hight:'100%',
      background: 'rgba(0,0,0,0.85)',
      backdropfilter: 'contrast(5) blur(100px)',

    };

    return(
        <div class="jumbotron-fluid-2 text-center" >
     
        <div class="container-fluid p-5 text-center" style={style2}>

            <div class="row pb-5 mt-5">
       
              <div class="col-12 col-lg-12 col-md-12 my-auto  text-center">
              
                  <img src="img/img.jpeg" class="rounded-circle" alt="Cinque Terre" width="200" height="200"/> 
              
              </div>
                <div class="col-12 col-lg-12 col-md-12 mt-5 p-0 mb-3 my-auto text-center">

                    <h1 class="font-color-header border-text-color text-sm-center text-lg-center text-sm-center mt-4 p-0 "><span class="color-third"> {this.props.first}</span> {this.props.second} </h1>
                    
                    <p class="text-white  text-sm-center text-lg-center text-sm-center p-0 m-0 ">{this.props.comment}</p>
                
                    <div class="row p-0 m-0 justify-content-center ">

                        <div class="col-auto p-0 m-1">
                            
                            <a href="https://linkedin.com/in/josé-i-gutiérrez-b-62a007112 " target="_blank">
                                <img src= {imageIn} class=""   height="25" width="25"/>
                            </a>

                        </div>

                        <div class="col-auto p-0 m-1 ">
                            
                            <a href="https://github.com/LordSaac" target="_blank">
                                <img src= {imageGit} class=""   height="25" width="25"/>
                            </a>

                        </div>

                        <div class="col-auto p-0 m-1 ">
                            <a href="https://www.instagram.com/isaac.kiri2693/" target="_blank">
                                    <img src= {imageInst} class=""   height="25" width="25"/>
                            </a>
                        </div>

                    </div>
            
                </div>
              
            </div>
        </div>
      </div>
    )
  }

}

export default HeaderAbout