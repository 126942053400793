import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation_lottie/sender.json'
import Header from '../../Components/Conteiners/HeaderContact' 

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  with:20,
  height: 20,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class SendEmail extends React.Component {

  render(){

    return(
      <div class="container">

             
             <h1 class = "text-center "> Success your was message sended! </h1>
             <p class = "text-gray text-center ">Thank for contact</p>

     </div>
    )
  }
}

export default SendEmail
