import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import * as DateController from '../../Helpers/DateController'


    export const ViewMoreContent = ({ publishs }) => {
    return (
        <div className="wrapper">
             <div class="row mx-auto max-content justify-content-center mb-5">
                  
              

                  <div class = "container py-5">
                  
                   <div class="card-group p-lg-3">
                   
                {publishs.state.publishs.map((item,index) => (

                    <div class="col-md-4 py-3" onClick={e => publishs.reload()}>

                        <Link to={'/Blog/' + item.postId } >
                      
                        <div class="card text-white bg-dark" articlekey={item.postId}>
                           
                            <img src={String(item.imgUrl)} class="card-img-top" alt="Card Component" height={200}/>
                              <div class="card-body">
                                <div class="card-title">
                                <h5 class=" text-left size-h5">{item.title}</h5>
                               </div>
                               
                              </div>
                            
                             <div class="card-footer bg-dark text-left size-p">
                             
                                     &nbsp; By {item.autorName} <span> -   {DateController.timeAgo.format(new Date(item.createDate) - 2 * 24 * 60 * 60 * 1000, 'twitter')} </span>

                               <a href="javascript:;" class="text-secondary float-right"><span>&bull;&bull;&bull;</span></a></div>

               
                        </div>

                       
                              </Link>
                            </div>

                       ))}

                        </div>

                    </div>
                  </div>
        </div>
      )
    };
  



export default ViewMoreContent;