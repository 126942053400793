import React, { Component } from 'react';
import {
    FacebookShareCount,
    OKShareCount,
    PinterestIcon,
    PinterestShareButton,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount,
  } from "react-share";
  import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon
    } from 'react-share';

    
class ShareBlog extends Component {
  render() {

    return (
        <div className="wrapper">


         
             <FacebookShareButton
                                        className="m-0"
                                         url={'https://itzaartsgallery.com/Details?title='  +  encodeURIComponent(this.props.id) }
                                         imageURL={this.props.img}
                                         title={this.props.title} 
                                         quote={this.props.title}
                                         description={this.props.desc} >
                                         <FacebookIcon
                                          size={this.props.size} round={this.props.rounded}
                                         />
                                         </FacebookShareButton>

              <PinterestShareButton
                                        className="m-0"
                                         url={'https://itzaartsgallery.com/Details?title='  +  encodeURIComponent(this.props.id)}
                                         imageURL={this.props.img}
                                         title={this.props.title} 
                                         quote={this.props.title}
                                         description={this.props.desc} >
                                         <PinterestIcon
                                          size={this.props.size} round={this.props.rounded}
                                         />
                                         </PinterestShareButton>

            <WhatsappShareButton
                                        className="m-0"
                                         url={'https://itzaartsgallery.com/Details?title=' +  encodeURIComponent(this.props.id)}
                                         imageURL={this.props.img}
                                         quote={this.props.title}
                                         description={this.props.desc} >
                                         <WhatsappIcon
                                          size={this.props.size} round={this.props.rounded}
                                         />
                                         </WhatsappShareButton>

            <EmailShareButton
                                        className="m-0"
                                         url={'https://itzaartsgallery.com/Details?title='  +  encodeURIComponent(this.props.id)}
                                         imageURL={this.props.img}
                                         title={this.props.title} 
                                         quote={this.props.title}
                                         description={this.props.desc} >
                                         <EmailIcon
                                          size={this.props.size} round={this.props.rounded}
                                         />
                                         </EmailShareButton>

                
                        
          
        </div>
      )
  }
}


export default ShareBlog;