import React from 'react'
import NumberCounter from 'number-counter'; 
import Header from '../../Conteiners/Header'
import HeaderAbout from '../../Conteiners/HeaderAbout'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MetaTag from '../../Conteiners/MetaReact'

import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage,useIntl } from "react-intl";

class About extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
    render(){
      return (

        <div class="Content-st">


       <MetaTag title = "header.about" pages = "OK" desc = 'about.section.history.im.history'/>

        <div class="hfeed site" id="page">


             
             <div id="content" class="site-content">
             <div class="ast-container">
             <div id="primary" class="content-area primary">

              <main id="main" class="s">
                  
                  <article class="post-11 page type-page status-publish ast-article-single" id="post-11" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                      <header class="entry-header ast-no-title ast-header-without-markup"></header> 

                      <div class="entry-content clear" itemprop="text">

                         <div data-elementor-type="wp-post" data-elementor-id="11" class="elementor elementor-11">
             
                              <section class="elementor-section elementor-top-section elementor-element elementor-element-69a61f19 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="69a61f19" data-element_type="section" data-settings='{"background_background":"classic"}'>

                                              <div class="elementor-background-overlay"></div>
                                              <div class="elementor-container elementor-column-gap-default">
                                              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15912662" data-id="15912662" data-element_type="column">
                                              <div class="elementor-widget-wrap elementor-element-populated">
                                              <div class="elementor-element elementor-element-8ed6ae0 elementor-widget elementor-widget-heading" data-id="8ed6ae0" data-element_type="widget" data-widget_type="heading.default">
                                              <div class="elementor-widget-container">
                                              <h1 class="elementor-heading-title elementor-size-default"><FormattedMessage id="about.section.title" /></h1> </div>
                                              </div>
                                              </div>
                                              </div>
                                              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7dd1dc0b" data-id="7dd1dc0b" data-element_type="column">
                                              <div class="elementor-widget-wrap">
                                              </div>
                                              </div>
                                              </div>
                                              
                              </section>
                              
                              <section class="elementor-section elementor-top-section elementor-element elementor-element-981148e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="981148e" data-element_type="section" data-settings='{"background_background":"classic"}'>
                                     
                                      <div class="elementor-container elementor-column-gap-default">
          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a95bcc4" data-id="a95bcc4" data-element_type="column">

          <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-e924372 uael-heading-align-left uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading" data-id="e924372" data-element_type="widget" data-widget_type="uael-advanced-heading.default">
          <div class="elementor-widget-container">
          <div class="uael-module-content uael-heading-wrapper">
          <h2 class="uael-heading">
          <span class="uael-heading-text elementor-inline-editing uael-size--default" data-elementor-setting-key="heading_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="about.section.summary" /></span>
          </h2>
          <div class="uael-subheading elementor-inline-editing" data-elementor-setting-key="heading_description" data-elementor-inline-editing-toolbar="advanced">
          "<FormattedMessage id="about.section.summary.one" />" </div>
          </div>
          </div>
          </div>
          <div class="elementor-element elementor-element-fac2577 elementor-widget elementor-widget-text-editor" data-id="fac2577" data-element_type="widget" data-widget_type="text-editor.default">
          <div class="elementor-widget-container">
          <p><FormattedMessage id="about.section.summary.two" /></p>
          <p><FormattedMessage id="about.section.summary.three" /></p> </div>
          </div>
          </div>
          </div>
          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-31ad23d" data-id="31ad23d" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-442f2db elementor-widget elementor-widget-image" data-id="442f2db" data-element_type="widget" data-widget_type="image.default">
          <div class="elementor-widget-container">
          <img fetchpriority="high" decoding="async" width="700" height="900" src="images/artist-about.jpg" class="attachment-large size-large wp-image-266" alt="" srcset="images/artist-about.jpg 700w, images/artist-about-233x300.jpg 233w, images/artist-about-400x514.jpg 400w" sizes="(max-width: 700px) 100vw, 700px"/> </div>
          </div>
          </div>
          
          </div>
                                      </div>

                              </section>

                              <section class="elementor-section elementor-element-section-2 elementor-top-section elementor-element elementor-element-faa2786 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="faa2786" data-element_type="section" data-settings='{"background_background":"classic"}'>


                                 <div class="elementor-background-overlay"></div>
                                 <div class="elementor-container elementor-column-gap-default">
                                <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a68deed" data-id="a68deed" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-fa3a5c8 elementor-widget elementor-widget-uael-infobox" data-id="fa3a5c8" data-element_type="widget" data-widget_type="uael-infobox.default">
                                <div class="elementor-widget-container">
                                <div class="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-none">
                                <div class="uael-infobox-left-right-wrap">
                                <div class="uael-infobox-content">
                                  
                                <div class="uael-infobox-title-wrap"><h3 class="uael-infobox-title-prefix elementor-inline-editing colot-about-12" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="about.section.summary.im" /></h3><h2 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">Itza Morales</h2></div> <div class="uael-infobox-text-wrap">
                                <div class="uael-infobox-text elementor-inline-editing colot-about-12" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                <FormattedMessage id="about.section.history.im.history" />
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                <div class="elementor-element elementor-element-84cd3ea elementor-widget elementor-widget-image" data-id="84cd3ea" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                <img decoding="async" width="138" height="41" src="images/artist-sign-free-img.webp" class="attachment-large size-large wp-image-89" alt=""/> </div>
                                </div>
                                </div>
                                </div>
                                <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-aecbeb7" data-id="aecbeb7" data-element_type="column">
                                <div class="elementor-widget-wrap">
                                </div>
                                </div>
                                 </div>

                              </section>

                              <section class="elementor-section elementor-top-section elementor-element elementor-element-981148e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="981148e" data-element_type="section" data-settings='{"background_background":"classic"}'>
                                    
                                  <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-ec21525" data-id="ec21525" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                              <div class="elementor-element elementor-element-b7661ca uael-ins-normal elementor-widget elementor-widget-uael-image-gallery" data-id="b7661ca" data-element_type="widget" data-settings='{"slides_to_show":3,"column_gap":{"unit":"px","size":5,"sizes":[]},"slides_to_show_tablet":3,"slides_to_show_mobile":2,"slides_to_scroll":1,"slides_to_scroll_tablet":1,"slides_to_scroll_mobile":1,"column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]}}'>
                                                  <div class="elementor-widget-container">
                                                    
                                                      <img decoding="async" width="668" height="532" src="images/ocean_image.jpg" class="attachment-medium_large size-medium_large wp-image-77" alt="" srcset="images/ocean_image.jpg 768w, images/ocean_image.jpg 300w, images/ocean_image.jpg 850w" sizes="(max-width: 768px) 100vw, 768px"/>
                                                    
                                                      <div class="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                          <div data-image_carousel='{"slidestoshow":3,"slidestoscroll":1,"autoplayspeed":3000,"autoplay":true,"infinite":true,"pauseonhover":true,"speed":500,"arrows":false,"dots":false,"rtl":false,"responsive":[{"breakpoint":1024,"settings":{"slidestoshow":3,"slidestoscroll":1}},{"breakpoint":767,"settings":{"slidestoshow":2,"slidestoscroll":1}}]}' class="uael-img-gallery-wrap uael-img-carousel-wrap uael-nav-none" data-filter-default="All">
                                                              <div class="uael-grid-item uael-img-gallery-item-1">
                                                                  <div class="uael-grid-item-content">
                                                                      <figure class="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                          <div class="uael-grid-img-thumbnail uael-ins-target">
                                                                              <img decoding="async" width="768" height="632" src="images/frame4-free-img-1-768x632.webp" class="attachment-medium_large size-medium_large wp-image-77" alt="" srcset="images/frame4-free-img-1-768x632.webp 768w, images/frame4-free-img-1-300x247.webp 300w, images/frame4-free-img-1.webp 850w" sizes="(max-width: 768px) 100vw, 768px"/>
                                                                          </div>
                                                                          <div class="uael-grid-img-overlay"></div>
                                                                      </figure>
                                                                  </div>
                                                              </div>
                                            
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-eaed9f6 container-2342" data-id="eaed9f6" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                              <div class="elementor-element elementor-element-87fc296 elementor-widget elementor-widget-uael-infobox" data-id="87fc296" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                  <div class="elementor-widget-container">
                                                      <div class="uael-module-content uael-infobox uael-infobox-left uael-infobox-link-type-button">
                                                          <div class="uael-infobox-left-right-wrap">
                                                              <div class="uael-infobox-content">
                                                                  <div class="uael-infobox-title-wrap">
                                                                      <h3 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="about.section.popular.picture" /></h3>
                                                                  </div>
                                                                  <div class="uael-infobox-text-wrap">
                                                                      <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced"><FormattedMessage id="about.section.popular.about" /></div>
                                                                      <div class="uael-button-wrapper elementor-button-wrapper">
                                                                      <br></br> 
                                                                           <Link to={'/Galery' } class="elementor-button-link elementor-button elementor-size-sm">
                                                                              <span class="elementor-button-content-wrapper">
                                                                                  <span class="elementor-align-icon-right elementor-button-icon"></span>
                                                                                
                                                                                  <span class="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none"><FormattedMessage id="btn.galery" /></span>
                                                                              </span>
                                                                          </Link>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              </section>


                        </div>

                       </div>

              </article>
              </main>
              </div>
              </div> 
              </div>

        </div>

  </div>
)
    }
  
}



  export default About


