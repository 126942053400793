import React, { Component } from 'react'
import { Helmet } from "react-helmet";



class MetaBlog extends React.Component {

  render(){

    return(
<div>
    <Helmet>



       
         <title>{this.props.title}</title>
         
         {/* <!-- HTML Meta Tags --> */}
         <meta property="og:title" content={this.props.title} data-react-helmet="true"/>
         <meta property="og:site_name" content="Another Programmer" data-react-helmet="true"/>
         <meta property="og:url" content="https://anotherprogrammer.com/" data-react-helmet="true"/>
         <meta property="og:description" content={this.props.desc} data-react-helmet="true"/>
         <meta property="og:type" content="article" data-react-helmet="true"/>
         <meta property="og:image" content={this.props.img } data-react-helmet="true"></meta>

         <meta name="keywords" content="Programing,HTML,CSS,XML,JavaScript,Panamá,Blog Code,Anohter Programmer,Code"/>
         <meta name="author" content="Isaac G. Gutiérrez"></meta>

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={this.props.title} />
        <meta
          itemprop="description"
          content={this.props.desc}
        />
        <meta
          itemprop="image"
          content={this.props.img }
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://anotherprogrammer.com" />
        <meta property="og:type" content="Blog" />
        <meta property="og:title" content={this.props.title} />
        <meta
          property="og:description"
          content={this.props.desc}
        />
        <meta
          property="og:image"
          content={this.props.img}
        />

  

    </Helmet>
 </div>
    )
  }

}

export default MetaBlog