import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation_lottie/building_page.json'
import Header from '../../Components/Conteiners/Header' 

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class BuildingPage extends React.Component {

  render(){

    return(
      <div className="Content-st">

      <div>
        
            <Header/>

               <div class ="row mb-4 justify-content-center">
                <div class ="col-12 col-lg-7 col-md-6 col-sm-12 p-4 mb-5 mt-5 my-auto">
             <Lottie  options={defaultOptions}/>
             <h1 class = "text-center "> Ups! </h1>
             <p class = "text-gray text-center ">Sorry, Building in page </p>
             </div>
         </div>

      </div>

     </div>
    )
  }

}

export default BuildingPage
