import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation_lottie/83358-contact-green.json'

var logo = require('../../assets/Image/logo1.png')


const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


class HeaderContact extends React.Component {

  render(){

    const style2 = {
      position: 'relative',
      width: '100%',
      hight:'100%',
      background: 'rgba(0,0,0,0.85)',
      backdropfilter: 'contrast(5) blur(100px)',
    };

    return(
        <div class="jumbotron-fluid text-center" >
     
        <div class="container-fluid p-5 text-center" style={style2}>

            <div class="row ">
       
              <div class="col-auto col-lg-2 col-md-12 col-md-1 my-5">
              
                  <Lottie class="d-lg-block  d-none" options={defaultOptions}/>
              
              </div>
                <div class="col-auto col-lg-10 col-md-10 mt-5 p-0 mb-3 my-auto">

                     <h1 class="font-color-header  border-text-color text-sm-center text-lg-left text-sm-left mt-4 p-0 text-primary-1">{this.props.first} <small class="color-third">{this.props.second}</small></h1>
                     <p class="text-white  text-sm-center text-lg-left text-sm-left p-0 ">{this.props.comment}</p>

                </div>
            </div>
        </div>
      </div>
    )
  }

}

export default HeaderContact