import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import PublishsService from  '../../services/PublishsService';
import PublishingService from  '../../services/PublishingService';
import About from './Pages/About';
import Resume from './Pages/Resume';
import Store from './Pages/Store';
import Galery from './Pages/Galery';
import Details from './Pages/Details';
import Header from '../Conteiners/Header'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Publish from './Pages/Publish';


const NavBar = () => {
  return  <div className="NavBasr-st">

  <Router>
  <Header page = "About"/>
         <Switch>
           
           <Route exact path='/' component={Publish} />
           <Route path='/Blog/:id' component={PublishingService} />
           <Route path='/About' component={About} /> 
           <Route path='/Galery' component={Galery} /> 
           <Route path='/Store' component={Store} />
           <Route path='/Details' component={Details} />      
           <Route path='/Resume' component={Resume} />   
  
  
           {/* <Route path='/SweetNightmares' component={SwiftNightmare} />   */}
        </Switch>
   </Router>
      </div>;
};

export default NavBar;
