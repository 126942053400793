import React from 'react';


import Publishing from '../Components/Conteiners/Pages/publishing';
import LoadingPost from '../Components/Animation/LoadingPost';
import HeaderError from '../Components/Animation/HeaderError';
import * as Config from './config';




class PublishingService extends  React.Component {

    constructor(props) {
        super(props);
          
          const { match } = this.props;
          this.state = { publishing: []}
          this.nextPage = '0';
          this.titlePage = '1';
          this.postId = match.params.id; 
        
    }
  
    nexContent = (event,title) => {

    }
  
    componentDidMount() {
    window.scrollTo(0, 0);
      Config.instance.get(Config.BaseUrl + Config.GetGetPosts + this.postId)
          .then(res => {
                const coins = res.data;
                this.setState({ publishing: coins});
       }).catch(function(error) {
               this.setState({load_failed: true});
         }.bind(this));
         
    }
  
  
   render() {
    //alert(JSON.stringify(this.state.publishing.imgUrl));
    if(Object.keys(this.state.publishing).length  > 0)
        return (<Publishing publishing = {this}/>);
    else if(this.state.load_failed)
        return (<HeaderError/>);
    else
        return (<LoadingPost/>);
   }
  }
  
  export default PublishingService;