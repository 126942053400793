import React from 'react';
import FooterStandar from './Components/Footer/FooterStandar';
import NavBar from './Components/Conteiners/NavBar';
import MetaReact from './Components/Conteiners/MetaReact';
import MetaReact2 from './Components/Conteiners/MetaReact2';
import MetaReactBlog from './Components/Conteiners/MetaBlog';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from 'react-intl';
import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom';

// Template resources
// // import './assets/itza_css/all.min.css'
// import './assets/itza_css/animations.min.css'
// import './assets/itza_css/blocks.css'
// //import './assets/itza_css/brands.min.css'
// //import './assets/itza_css/elementor-icons.min.css'
// //import './assets/itza_css/fontawesome.min.css'
// import './assets/itza_css/frontend.min.css'
// import './assets/itza_css/main.min.css'
 // import './assets/itza_css/post-10.css'

// //import './assets/itza_css/regular.min.css'
// //import './assets/itza_css/solid.min.css'
// import './assets/itza_css/style-main.css'
// import './assets/itza_css/swiper.min.css'
// import './assets/itza_css/uael-frontend.min.css'
// import './assets/itza_css/v4-shims.min.css'


import English from './lang/en.json';
import Spanish from './lang/es.json';
import {getLanguage} from '../src/Helpers/LenguajesHelper'

import About from '../src/Components/Conteiners/Pages/About';
import Resume from '../src/Components/Conteiners/Pages/Resume';
import Store from '../src/Components/Conteiners/Pages/Store';
import Galery from '../src/Components/Conteiners/Pages/Galery';
import Details from '../src/Components/Conteiners/Pages/Details';
import { BrowserRouter as Router, Switch, Route, Link,BrowserRouter } from 'react-router-dom';
import Publish from '../src/Components/Conteiners/Pages/Publish';


const locale = navigator.language;

let lang;
let urlPath = window.location.href;



const APP = () => {
  return  <div className="Rauters">
  
  <BrowserRouter>

<Switch>
<Route exact path='/' component={Publish} />
     <Route path='/About' component={About} /> 
     <Route path='/Galery' component={Galery} /> 
     <Route path='/Store' component={Store} />
     <Route path='/Details' component={Details} />      
</Switch>
</BrowserRouter>
      </div>;
};

if (getLanguage(locale) === 'Spanish') {
    lang = Spanish;
} else {
    lang = English;
}



  ReactDOM.render(
    <IntlProvider locale ={locale} messages={lang}>
        <FooterStandar />
    </IntlProvider>
    , 
    document.getElementById('Footer-st'));
    


    ReactDOM.render(
    <IntlProvider locale ={locale} messages={lang}>
      <NavBar/>
    </IntlProvider>,
    document.getElementById('NavBar-st')
    );
    ReactDOM.render(
    <IntlProvider locale ={locale} messages={lang}>
    </IntlProvider>
    ,document.getElementById('Meta-st'));

 
serviceWorker.unregister();
