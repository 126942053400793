import React from 'react';


import Publish from '../Components/Conteiners/ViewMoreContent';
import LoadingPublish from '../Components/Animation/LoadingDownContent';
import HeaderError from '../Components/Animation/HeaderError';
import BuildingPage from '../Components/Animation/BuildingPage';
import * as Config from './config';
import Header from '../../src/Components/Conteiners/Header'

class ViewMoreContentService extends  React.Component {
  // Add locale-specific relative date/time formatting rules.
  // TimeAgo.addLocale(en)
  // Create relative date/time formatter.
 /// Use https://reacttraining.com/react-router/native/example/Basic 
  constructor(props) {
      super(props);

        this.state = { publishs: [] , navPage:[]}
  }

  reload = () => {
    window.location.reload(); 
 }

  componentDidMount() {

    Config.instance.get(Config.BaseUrl + Config.GetRandomPublishs + this.props.notId + '/' + this.props.max)
        .then(res => {
              const coins = res.data;
              
              this.setState({ publishs: coins});
   
            }).catch(function(error) {
       
             this.setState({load_failed: true});

       }.bind(this));

  }


 render() {
   if(!Config.onDeveloper){
     if(this.state.publishs.length > 0)
         return (<Publish publishs = {this} />);
     else if(this.state.load_failed)
        return (<p class="text-center pb-5"></p>);
     else
        return (<LoadingPublish/>);
   }else
        return (<BuildingPage/>);
 }
}

export default ViewMoreContentService;
