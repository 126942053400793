import React from 'react'
import * as DateController from '../../../Helpers/DateController'
import Header from '../../Conteiners/Header'
import MetaTag from '../../Conteiners/MetaReact'
import Contact from '../../../services/ContactService'
import ViewMoreContent from '../../../services/ViewMoreContentService'
import { Progress } from 'react-sweet-progress';
import { FormattedMessage,useIntl } from "react-intl";

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

    export const Publish = ({ publishs }) => {
      
       
      return (

      <div className="Content-st">

        <MetaTag title = "header.home"  pages = "OK"/>
        
          <div class="hfeed site" id="page">
            
              <div id="content" class="site-content">
                  <div class="ast-container">
                  <div id="primary" class="content-area primary">
                    <main id="main" class="site-main">
                        <article class="post-10 page type-page status-publish ast-article-single" id="post-10" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                            <header class="entry-header ast-no-title ast-header-without-markup"></header> 

                            <div class="entry-content clear" itemprop="text">
                               <div data-elementor-type="wp-post" data-elementor-id="10" class="elementor elementor-10">
                                   
                               <section
                                    className="elementor-section elementor-top-section elementor-element elementor-element-4285018 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="4285018"
                                    data-element_type="section"
                                    data-settings={{" background_background":"classic"}}>
                                          <div class="elementor-container elementor-column-gap-default">
                                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0c65b18" data-id="0c65b18" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                          <div class="elementor-element elementor-element-1680dea elementor-widget elementor-widget-uael-infobox" data-id="1680dea" data-element_type="widget" data-widget_type="uael-infobox.default">
                                          <div class="elementor-widget-container">
                                          <div class="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                                          <div class="uael-infobox-left-right-wrap">
                                          <div class="uael-infobox-content">
                                          <div class="uael-infobox-title-wrap"><h4 class="uael-infobox-title-prefix elementor-inline-editing" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                                            <FormattedMessage id="home.header.present" /> 
                                            </h4><h1 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="home.header.title" /></h1></div> <div class="uael-infobox-text-wrap">
                                          <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                          <FormattedMessage id="home.header.desc" />
                                            </div>
                                          <div class="uael-button-wrapper elementor-button-wrapper">
                                            
                                          <Link to={'/Galery' }  class="elementor-button-link  elementor-button elementor-size-sm">
                                          <span class="elementor-button-content-wrapper">
                                          <span class="elementor-align-icon-right elementor-button-icon">
                                          </span>
                                          <span class="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none">  <FormattedMessage id="btn.galery" /></span>
                                          </span>
                                          </Link>
                                          </div>
                                          </div>
                                          </div>
                                          </div>
                                          </div>
                                          </div>
                                          </div>
                                          </div>
                                          </div>
                                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f17ba32" data-id="f17ba32" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                          <div class="elementor-element elementor-element-dc9815f elementor-widget elementor-widget-image" data-id="dc9815f" data-element_type="widget" data-widget_type="image.default">
                                          <div class="elementor-widget-container">
                                          <img fetchpriority="high" decoding="async" width="700" height="850" src="images/frame-12-free-img-1.webp" class="elementor-animation-float attachment-large size-large wp-image-183" alt="" srcset="images/frame-12-free-img-1.webp 700w, images/frame-12-free-img-1.webp 247w" sizes="(max-width: 700px) 100vw, 700px"/> </div>
                                          </div>
                                          </div>
                                          </div>
                                          </div>

                                </section>
                              
                                <section
      className="elementor-section elementor-top-section elementor-element elementor-element-c7ea855 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="c7ea855"
      data-element_type="section"
      data-settings={{" background_background":"classic"}}>
                              <div class="elementor-background-overlay"></div>
                              <div class="elementor-container elementor-column-gap-default">
                              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4e01a78" data-id="4e01a78" data-element_type="column">
                              <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-6ccd84e elementor-widget elementor-widget-image" data-id="6ccd84e" data-element_type="widget" data-widget_type="image.default">
                              <div class="elementor-widget-container">
                              <img decoding="async" width="700" height="850" src="images/frame2-free-img.webp" class="elementor-animation-float attachment-large size-large wp-image-71" alt="" srcset="images/frame2-free-img.webp 700w, images/frame2-free-img-247x300.webp 247w" sizes="(max-width: 700px) 100vw, 700px"/> </div>
                              </div>
                              <div class="elementor-element elementor-element-a4ba463 uael-heading-align-left uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading" data-id="a4ba463" data-element_type="widget" data-widget_type="uael-advanced-heading.default">
                              <div class="elementor-widget-container">
                              <div class="uael-module-content uael-heading-wrapper">
                              <h4 class="uael-heading">
                              <span class="uael-heading-text elementor-inline-editing uael-size--default" data-elementor-setting-key="heading_title" data-elementor-inline-editing-toolbar="basic">Behind the Face</span>
                              </h4>
                              <div class="uael-subheading elementor-inline-editing" data-elementor-setting-key="heading_description" data-elementor-inline-editing-toolbar="advanced">
                              <FormattedMessage id="home.section.pict.one.material" />
                                <br/>
                                <FormattedMessage id="home.section.pict.one.size" />
                                </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5428724" data-id="5428724" data-element_type="column">
                              <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-3ed3413 elementor-widget elementor-widget-uael-infobox" data-id="3ed3413" data-element_type="widget" data-widget_type="uael-infobox.default">
                              <div class="elementor-widget-container">
                              <div class="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                              <div class="uael-infobox-left-right-wrap">
                              <div class="uael-infobox-content">
                              <div class="uael-infobox-title-wrap"><h2 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">   <FormattedMessage id="home.section.recent" /> </h2></div> <div class="uael-infobox-text-wrap">
                              <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                              <FormattedMessage id="home.section.recent.dect" /> </div>
                              <div class="uael-button-wrapper elementor-button-wrapper">
                              <Link to={'/Galery' } class="elementor-button-link  elementor-button elementor-size-sm">
                              <span class="elementor-button-content-wrapper">
                              <span class="elementor-align-icon-right elementor-button-icon">
                              </span>
                              <span class="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none"> <FormattedMessage id="btn.galery" /></span>
                              </span>
                              </Link>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div class="elementor-element elementor-element-caba724 elementor-widget elementor-widget-image" data-id="caba724" data-element_type="widget" data-widget_type="image.default">
                              <div class="elementor-widget-container">
                              <img decoding="async" width="850" height="700" src="images/frame4-free-img-1.webp" class="elementor-animation-float attachment-large size-large wp-image-77" alt="" srcset="images/frame4-free-img-1.webp 850w, images/frame4-free-img-1-300x247.webp 300w, images/frame4-free-img-1-768x632.webp 768w" sizes="(max-width: 850px) 100vw, 850px"/> </div>
                              </div>
                              <div class="elementor-element elementor-element-e7084f6 uael-heading-align-left uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading" data-id="e7084f6" data-element_type="widget" data-widget_type="uael-advanced-heading.default">
                              <div class="elementor-widget-container">
                              <div class="uael-module-content uael-heading-wrapper">
                              <h4 class="uael-heading">
                              <span class="uael-heading-text elementor-inline-editing uael-size--default" data-elementor-setting-key="heading_title" data-elementor-inline-editing-toolbar="basic"> Detrás De Tus Pasos</span>
                              </h4>
                              <div class="uael-subheading elementor-inline-editing" data-elementor-setting-key="heading_description" data-elementor-inline-editing-toolbar="advanced">
                              <FormattedMessage id="home.section.pict.two.material" />
                                <br/>
                                <FormattedMessage id="home.section.pict.two.size" />
                                </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                                </section>
                                
                                <section class="elementor-section elementor-top-section elementor-element elementor-element-1223c64 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1223c64" data-element_type="section" data-settings='{ "background_background": "classic" }'>
                                      <div class="elementor-background-overlay"></div>
                                      <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-ab5c6cb" data-id="ab5c6cb" data-element_type="column">
                                     
                                     
                                      <section class="elementor-section elementor-inner-section elementor-element elementor-element-a9b8dd4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a9b8dd4" data-element_type="section">
                                            <div class="elementor-container elementor-column-gap-default">
                                              <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1e35e71" data-id="1e35e71" data-element_type="column" data-settings='{"background_background":"classic"}'>
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                  <div class="elementor-element elementor-element-dc5a881 elementor-widget elementor-widget-spacer" data-id="dc5a881" data-element_type="widget" data-widget_type="spacer.default">
                                                    <div class="elementor-widget-container">
                                                      <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-554f50c" data-id="554f50c" data-element_type="column" data-settings='{"background_background":"classic"}'>
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                  <div class="elementor-element elementor-element-7f1f1cb elementor-hidden-phone elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="7f1f1cb" data-element_type="widget" data-widget_type="divider.default">
                                                    <div class="elementor-widget-container">
                                                      <div class="elementor-divider">
                                                        <span class="elementor-divider-separator"></span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="elementor-element elementor-element-6c58527 elementor-widget elementor-widget-uael-infobox" data-id="6c58527" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                    <div class="elementor-widget-container">
                                                      <div class="uael-module-content uael-infobox uael-infobox-left uael-infobox-link-type-link">
                                                        <div class="uael-infobox-left-right-wrap">
                                                          <div class="uael-infobox-content">
                                    
                                                            <div class="uael-infobox-title-wrap">
                                                              <h3 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">
                                                                <FormattedMessage id="home.section.history.title" />
                                                                </h3>
                                                            </div>
                                                            <div class="uael-infobox-text-wrap">
                                                              <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                                              <FormattedMessage id="home.section.history.desc" />
                                                              </div>
                                                              <div class="uael-infobox-cta-link-style">

                                                                <span class="uael-infobox-link-icon uael-infobox-link-icon-after"></span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                      </section>

                                      </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4c09d95 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4c09d95" data-element_type="section" data-settings='{"background_background":"classic"}'>
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-71e79a6" data-id="71e79a6" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-b20f91a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b20f91a" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f7986d6" data-id="f7986d6" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-d5c5744 uael-heading-align-right uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading" data-id="d5c5744" data-element_type="widget" data-widget_type="uael-advanced-heading.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="uael-module-content uael-heading-wrapper">
                                                                            <h2 class="uael-heading">
                                                                                <span class="uael-heading-text elementor-inline-editing uael-size--default" data-elementor-setting-key="heading_title" data-elementor-inline-editing-toolbar="basic">       
                                                                                 <FormattedMessage id="home.section.exibition" />
                                                                                 </span>
                                                                            </h2>
                                                                            <div class="uael-subheading elementor-inline-editing" data-elementor-setting-key="heading_description" data-elementor-inline-editing-toolbar="advanced">
                                                                                 <FormattedMessage id="home.section.exibition.desc" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69ee285" data-id="69ee285" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-4117e72 elementor-widget elementor-widget-uael-infobox" data-id="4117e72" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="uael-module-content uael-infobox uael-imgicon-style-normal uael-infobox-center infobox-has-photo uael-infobox-photo-above-title uael-infobox-link-type-none">
                                                                            <div class="uael-infobox-left-right-wrap">
                                                                                <div class="uael-infobox-content">
                                                                                    <div class="uael-module-content uael-imgicon-wrap">
                                                                                        <div class="uael-image" itemscope="" itemtype="http://schema.org/ImageObject">
                                                                                            <div class="uael-image-content elementor-animation-float">
                                                                                                <img loading="lazy" decoding="async" width="850" height="700" src="images/frame7-free-img.webp" class="attachment-full size-full wp-image-76" alt="" sizes="(max-width: 850px) 100vw, 850px"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="uael-infobox-title-wrap">
                                                                                        <p class="uael-infobox-title-prefix elementor-inline-editing" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"> 
                                                                                            <FormattedMessage id="home.section.pict.three.material" />
                                                                                             <br />
                                                                                            <FormattedMessage id="home.section.pict.three.size" />
                                                                                            <br />
                                                                                            <FormattedMessage id="home.section.pict.three.year" />
                                                                                        </p>
                                                                                        <h4 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">En el silencio</h4>
                                                                                    </div>
                                                                                    <div class="uael-infobox-text-wrap">
                                                                                        <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-9097030 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9097030" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dc4bc25" data-id="dc4bc25" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-1af9e8d elementor-widget elementor-widget-uael-infobox" data-id="1af9e8d" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="uael-module-content uael-infobox uael-imgicon-style-normal uael-infobox-center infobox-has-photo uael-infobox-photo-above-title uael-infobox-link-type-none">
                                                            <div class="uael-infobox-left-right-wrap">
                                                                <div class="uael-infobox-content">
                                                                    <div class="uael-module-content uael-imgicon-wrap">
                                                                        <div class="uael-image" itemscope="" itemtype="http://schema.org/ImageObject">
                                                                            <div class="uael-image-content elementor-animation-float">
                                                                                <img loading="lazy" decoding="async" width="700" height="850" src="images/floral-painting-3.webp" class="attachment-full size-full wp-image-439" alt="" srcset="images/floral-painting-3.webp 700w, images/floral-painting-3-247x300.jpg 247w, images/floral-painting-3-400x486.jpg 400w" sizes="(max-width: 700px) 100vw, 700px"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uael-infobox-title-wrap">
                                                                        <p class="uael-infobox-title-prefix elementor-inline-editing" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                                                                            
                                                                                            <FormattedMessage id="home.section.pict.four.material" />
                                                                                             <br />
                                                                                            <FormattedMessage id="home.section.pict.four.size" />
                                                                                            <br />
                                                                                            <FormattedMessage id="home.section.pict.four.year" />
                                                                            
                                                                            </p>
                                                                        <h4 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">Moza en el ocaso</h4>
                                                                    </div>
                                                                    <div class="uael-infobox-text-wrap">
                                                                        <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d14c1b7" data-id="d14c1b7" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-7a55c32 elementor-widget elementor-widget-uael-infobox" data-id="7a55c32" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="uael-module-content uael-infobox uael-imgicon-style-normal uael-infobox-center infobox-has-photo uael-infobox-photo-above-title uael-infobox-link-type-none">
                                                            <div class="uael-infobox-left-right-wrap">
                                                                <div class="uael-infobox-content">
                                                                    <div class="uael-module-content uael-imgicon-wrap">
                                                                        <div class="uael-image" itemscope="" itemtype="http://schema.org/ImageObject">
                                                                            <div class="uael-image-content elementor-animation-float">
                                                                                <img loading="lazy" decoding="async" width="700" height="850" src="images/frame3-free-img.webp" class="attachment-full size-full wp-image-72" alt="" srcset="images/frame3-free-img.webp 700w, images/frame3-free-img-247x300.webp 247w" sizes="(max-width: 700px) 100vw, 700px"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uael-infobox-title-wrap">
                                                                        <p class="uael-infobox-title-prefix elementor-inline-editing" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                                                                           
                                                                                             <FormattedMessage id="home.section.pict.five.material" />
                                                                                             <br />
                                                                                            <FormattedMessage id="home.section.pict.five.size" />
                                                                                            <br />
                                                                                            <FormattedMessage id="home.section.pict.five.year" />
                                                                            
                                                                            </p>
                                                                        <h4 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">Fuerza del océano</h4>
                                                                    </div>
                                                                    <div class="uael-infobox-text-wrap">
                                                                        <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6979e0b" data-id="6979e0b" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-033b28f elementor-widget elementor-widget-uael-infobox" data-id="033b28f" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="uael-module-content uael-infobox uael-imgicon-style-normal uael-infobox-center infobox-has-photo uael-infobox-photo-above-title uael-infobox-link-type-none">
                                                            <div class="uael-infobox-left-right-wrap">
                                                                <div class="uael-infobox-content">
                                                                    <div class="uael-module-content uael-imgicon-wrap">
                                                                        <div class="uael-image" itemscope="" itemtype="http://schema.org/ImageObject">
                                                                            <div class="uael-image-content elementor-animation-float">
                                                                                <img loading="lazy" decoding="async" width="700" height="850" src="images/frame5-free-img.webp" class="attachment-full size-full wp-image-74" alt="" srcset="images/frame5-free-img.webp 700w, images/frame5-free-img-247x300.webp 247w" sizes="(max-width: 700px) 100vw, 700px"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uael-infobox-title-wrap">
                                                                        <p class="uael-infobox-title-prefix elementor-inline-editing" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                                                                                            <FormattedMessage id="home.section.pict.six.material" />
                                                                                             <br />
                                                                                            <FormattedMessage id="home.section.pict.six.size" />
                                                                                            <br />
                                                                                            <FormattedMessage id="home.section.pict.six.year" />
                                                                            </p>
                                                                        <h4 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">Profundo</h4>
                                                                    </div>
                                                                    <div class="uael-infobox-text-wrap">
                                                                        <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                               </div>
                             </div>
                        </article>
                    </main>
                  </div>

                  </div>
                  
              </div>

          </div>

             
      </div>

      )
    };

export default Publish

