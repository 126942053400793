
import React from 'react'
import Header from '../../Conteiners/Header'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import MetaTag from '../../Conteiners/MetaTag'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MetaReact from '../../Conteiners/MetaReact'

class Resume extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
    render(){
  
      return(
   
   <div className="Content-st jumbotron-fluid-resume p-lg-5">

                <MetaReact title = "Resume"
                 img = "icon/ms-icon-144x144.png"
                 desc = "This blog was created with the purpose of sharing our experience during the development in different projects, we know that in each project we learn something new so we have the certainty that this experience is worth being shared."
                 autor = "Isaac G Banda"
                  />
           

       <div class="container background-resume  rounded p-3">
            <div class="row">
              <div class = "col-lg-12 text-right ">
                    {/* <a  href="https://drive.google.com/u/0/uc?id=1QaQTTi6uS7rhhsnQDLmTRylcWXekEcgi&export=download"  class = "text-center  text-black"> <i class="fas fa-download color-third grow flash"></i></a>
                */}
                </div>
              <div class = "col-lg-2 col-12 text-center my-auto">
                <img src="img/jose.png" class="rounded flash mt-lg-0 mt-5" alt="Cinque Terre" width="165" height="165"/> 
             </div>
             <div class = "col-lg-9 col-12 ">
                 <h1 class="text-lg-left text-center font-weight-light ">Hey, I'am <span class="color-primary">Isaac G. Banda</span></h1>
                 <hr class=""/>
                 <h6 class = "text-gray text-lg-left text-center text-gray d-none">Software Developer</h6>
                 <p class="text-justify">

                 I am a software developer, I have more than 5 years
of experience in the design and construction of
software on different platforms. My approach is
always to deliver a quality product and above all one
that delivers value to the end user. I am always in
constant learning for which I like to explore new
technologies that can give value to the products I
develop.
                 
                 </p>
             </div>
             </div>

             <div class="row mt-3">
             <div class = "col-lg-2 col-12 text-center my-auto">
  
                <i class="fas fa-user color-third2 text-resume-font-size"></i> 

             </div>
             <div class = "col-lg-10 col-12">
                 <h2 class="text-left color-primary">Profile</h2>
                
                 <h6 class="color-secundary p-0 m-0">Full Name</h6>
                 <hr class="p-0 m-0"/>
                 <p>José Isaac Gutiérrez Banda</p>

                 <h6 class="color-secundary p-0 m-0">Born</h6>
                 <hr class="p-0 m-0"/>
                 <p>26 Aug 1993 - Panamá</p>

                 <h6 class="color-secundary p-0 m-0 d-none">Email</h6>
                 <hr class="p-0 m-0 d-none"/>
                 <p class="d-none">jose19.26@hotmail.com</p>

                 <h6 class="color-secundary p-0 m-0 d-none">Phone</h6>
                 <hr class="p-0 m-0 d-none"/>
                 <p class="d-none">+507 6381-3932</p>

                 <h6 class="color-secundary p-0 m-0">Find Me</h6>
                 <hr class="p-0 m-0"/>
                 <div class="row justify-content-start m-0 p-0">
                    <div class="col-auto  m-0 p-1">
                        <a href="https://linkedin.com/in/josé-i-gutiérrez-b-62a007112" target="_blank"><i class="fab fa-linkedin grow"></i></a>
                     </div>
                     <div class="col-auto  m-0 p-1">
                        <a href="https://github.com/LordSaac" target="_blank"><i class="fab fa-github-square grow"></i></a>
                      </div>
                      <div class="col-auto  m-0 p-1">
                        <a href="https://www.instagram.com/isaac.kiri2693/" target="_blank"><i class="fab fa-instagram grow"></i></a>
                     </div>
               </div>


             </div>
             </div>


             <div class="row">
             <div class = "col-lg-2 col-12 text-center my-auto">
                 <i class="fas fa-graduation-cap color-third2 text-resume-font-size"></i>
             </div>
             <div class = "col-lg-10 col-12 mt-3">
                 <h2 class="text-left color-primary">Education</h2>
                
                 <h6 class="color-secundary p-0 m-0">Bachelor of Science</h6>
                 <hr class="p-0 m-0"/>
                 <p class="text-gray font-weight-light"> Panama Commercial Institute 2006 – 2011</p>
                  
                 <h6 class="color-secundary p-0 m-0">Mobile Development Certification</h6>
                 <hr class="p-0 m-0"/>
                 <p class="text-gray font-weight-light"> INADEH City of knowledge 2014</p>

                 <h6 class="color-secundary p-0 m-0">Degree in Software Development</h6>
                 <hr class="p-0 m-0"/>
                 <Link to={'/SweetNightmares' } class="color-third"><p class="text-gray font-weight-light"> Tecnology University of Panama 2013 – 2018</p></Link>
                 
           
                 <h6 class="color-secundary p-0 m-0">Data analysis certification with Power BI</h6>
                 <hr class="p-0 m-0"/>
                 <p class="text-gray font-weight-light"> New Horizonts Panamá 2019</p>

             </div>
             </div>


             <div class="row">
             <div class = "col-lg-2 col-12 text-center my-auto">
                <i class="fas fa-suitcase color-third2 text-resume-font-size"></i> 
             </div>
             <div class = "col-lg-10 col-12 mt-3">
                 <h2 class="text-lg-left text-center color-primary">Work Experience</h2>
                
                 <h6 class="color-secundary p-0 m-0">Sitel Panamá</h6>
                 <hr class="p-0 m-0"/>
                 <p class="text-gray font-weight-light"> Nokia Technical Support -<strong class="font-italic"> Mar 2012 – Feb 2015 </strong> </p>
             
               
                 <h6 class="color-secundary p-0 m-0">Technological University of Panama</h6>
                 <hr class="p-0 m-0"/>
                 <p class="text-gray font-weight-light"> Laboratory Maintenance -<strong class=" font-italic"> Jun 2015 – Dec 2016 </strong> </p>
             
                 <h6 class="color-secundary p-0 m-0">HyperNova Labs</h6>
                 <hr class="p-0 m-0"/>
                 <p class="text-gray font-weight-light"> IOS & Android Mobile Developer -<strong class=" font-italic"> Jan 2017 – Feb 2018 </strong> </p>
             
                 <h6 class="color-secundary p-0 m-0">Tropigas de Panamá</h6>
                 <hr class="p-0 m-0"/>
                 <p class="text-gray font-weight-light"> Software Development -<strong class=" font-italic"> Mar 2018 – current </strong> </p>
             

             </div>
             </div>


            <div class="row">
                <div class = "col-lg-2 col-12 text-center my-auto">
                    <i class="fas fa-code color-third2 text-resume-font-size"></i> 
                </div>
                <div class = "col-lg-10 col-12 mt-3">
                 <h2 class="text-lg-left text-center color-primary">Skills</h2>
                 <h5 class="color-secundary p-0 m-0 mt-3 text-lg-left text-center">Mobile Development</h5>
                 <hr class="p-0 m-0"/>

              <div class="row mt-2">
                 <div class = "col-6">
                  <p class=" m-0 p-0"> Android</p>
            
                {/* <Progress percent={60} theme={{
success: {
  symbol: '🏄‍',
  color: 'rgb(223, 105, 180)'
},
active: {
  symbol: ' ',
  color: '#a4c639'
  
},
default: {
  symbol: '😱',
  color: '#fbc630'
}
                 }}/>  */}
               <ul class="p-0 m-0">
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">Java</div></li>
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">Kotlin</div></li>
                 </ul>
             </div>

             <div class = "col-6">
              <p class=" m-0 p-0"> iOS</p>
             
                {/* <Progress percent={40} theme={{
success: {
  symbol: '🏄‍',
  color: 'rgb(223, 105, 180)'
},
active: {
  symbol: ' ',
  color: '#FC2B22'
  
},
default: {
  symbol: '😱',
  color: '#fbc630'
}
                 }}/>  */}

                  <ul class="p-0 m-0">
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">Objective C</div></li>
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">Swift</div></li>
                 </ul>
             </div>


             </div>


             <h5 class="color-secundary p-0 m-0 mt-3 text-lg-left text-center">Web & Backend Development</h5>
                 <hr class="p-0 m-0"/>

              <div class="row mt-2">
         

             <div class = "col-6">
              <p class="m-0 p-0">Build Page</p>
             
                {/* <Progress percent={65} theme={{
success: {
  symbol: '🏄‍',
  color: 'rgb(223, 105, 180)'
},
active: {
  symbol: ' ',
  color: '#0096D1'
  
},
default: {
  symbol: '😱',
  color: '#fbc630'
}
                 }}/>  */}

                  <ul class="p-0 m-0">
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">HTML 5</div></li>
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">CSS 3</div></li>
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">Boostrap 4</div></li>
                 </ul>
             </div>

             <div class = "col-6">
                  <p class=" m-0 p-0">Visual Studio</p>
             
                {/* <Progress percent={55} theme={{
success: {
  symbol: '🏄‍',
  color: 'rgb(223, 105, 180)'
},
active: {
  symbol: ' ',
  color: '#5C2D91'
  
},
default: {
  symbol: '😱',
  color: '#fbc630'
}
                 }}/> */}

                 <ul class="p-0 m-0">
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">NET Core 3.1</div></li>
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">C#</div></li>
                 </ul>  
             </div>

             <div class = "col-6 mt-2">
                  <p class=" m-0 p-0 ">React</p>
             
                {/* <Progress percent={40} theme={{
success: {
  symbol: '🏄‍',
  color: 'rgb(223, 105, 180)'
},
active: {
  symbol: ' ',
  color: '#60DAFB'
  
},
default: {
  symbol: '😱',
  color: '#fbc630'
}
                 }}/> */}
             
                 <ul class="p-0 m-0">
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">JavaScript</div></li>
      
                 </ul>  
      
  
             </div>

             </div>



             <h5 class="color-secundary p-0 m-0 mt-3 text-lg-left text-center">Data Bases</h5>
                 <hr class="p-0 m-0"/>

              <div class="row mt-2">
                 <div class = "col-6">
                  <p class=" m-0 p-0"> SQL</p>
            
                {/* <Progress percent={65} theme={{
success: {
  symbol: '🏄‍',
  color: 'rgb(223, 105, 180)'
},
active: {
  symbol: ' ',
  color: '#5ABB00'
  
},
default: {
  symbol: '😱',
  color: '#fbc630'
}
                 }}/>  */}
                 
                 <ul class="p-0 m-0">
                   <li class="p-0 m-0 color-primary ml-3"> <div class="text-gray font-weight-light">SQL Server </div></li>
                  <li class="p-0 m-0 color-primary ml-3"><div class="text-gray font-weight-light">My SQL</div></li>
                 </ul>  

             </div>
             <div class = "col-6">
                  <p class=" m-0 p-0"> NoSQL</p>
            
                {/* <Progress percent={35} theme={{
success: {
  symbol: '🏄‍',
  color: 'rgb(223, 105, 180)'
},
active: {
  symbol: ' ',
  color: '#FFCB2B'
  
},
default: {
  symbol: '😱',
  color: '#fbc630'
}
                 }}/>  */}

                 <ul class="p-0 m-0">
                   <li class="p-0 m-0  color-primary ml-3"> <div class="text-gray font-weight-light">Firebase </div></li>
                  <li class="p-0 m-0 color-primary ml-3"><div class="text-gray font-weight-light">MongDB</div></li>
                 </ul>  


             </div>

             </div>

      
                 

             </div>
             </div>
             <div class="row d-none">
                <div class = "col-lg-2 col-12 text-center my-auto">
                    <i class="fas fa-project-diagram color-third2 text-resume-font-size"></i> 
               
                </div>
                <div class = "col-lg-10 col-12 mt-3">
                 <h2 class="text-lg-left text-center color-primary">Portafolio</h2>
                 <h5 class="color-secundary p-0 m-0 mt-3 text-lg-left text-center">Mobile Development</h5>
                 <hr class="p-0 m-0"/>

             </div>
             </div>

             

             <div class="row">
               <div class="col-md-12  text-center mt-5">
               {/* <form >
                  <a  href="https://drive.google.com/u/0/uc?id=1QaQTTi6uS7rhhsnQDLmTRylcWXekEcgi&export=download"  class="test-button btn-2 pr-3 pl-3" value=""> <a class="fas fa-download text-white test-button"></a> Download Resume</a>
               </form> */}
              </div>
              </div>
         </div>
 
    </div>
      )
    }
  
  }
  
  export default Resume