const axios = require('axios');


export const instance = axios.create({
      timeout: 100000,
      Accept: 'application/json',
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'ccess-Control-Allow-Origin': '*',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiNWRlZmE4ZDU1ZmY3MDI0YmUwZDk1MmM2IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiJTdGFuZGFyZFVzZXIiLCJleHAiOjE3MzM3MDc0OTYsImlzcyI6InRocmVlbmluZS5jby51ayIsImF1ZCI6IlNhbXBsZUF1ZGllbmNlIn0.IEULrsgxqtqANpC79YLv8JbCVqOcf6ySrbZu8W55SKs'
      }

    });


export const BaseUrl = 'https://portal-gasn-on-borrowing-server.azurewebsites.net/api'// *README* I am in development #Master
//export const BaseUrl = 'http://localhost:5200/api' // *README* I am in development #MACBOOK
//export const BaseUrl = 'https://localhost:44330/api' // *README* I am in development #WINDOWS

export const GetRole = '/Roles'
export const GetPublishs = '/Posts'
export const GetNextPublishs = '/Posts/'
export const GetRandomPublishs = '/Posts/Random/'
export const GetGetPosts = '/Posts/'
export const PostEmail = '/Email'

//**Config Page */
export const onDeveloper = false; 
