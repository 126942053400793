import React, { Component } from 'react';

import { FormattedMessage } from "react-intl";

import { calculateDiscountedPrice } from '../../Helpers/MathHelper';

class ShowDetails extends Component {
    constructor(props) {
      super(props);

      this.carouselRef = React.createRef(); // Create a ref for the carousel

      this.state = {
        show: false
      };
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
  
    handleShow() {

      this.setState({ show: true });
      
    }
  
    handleClose() {

      this.setState({ show: false });
    
    }

    componentDidMount() {
        // Start the carousel when the component mounts
        this.carouselInstance = new window.bootstrap.Carousel(this.carouselRef.current, {
          interval: 4000, // Specify your desired interval here
        });
      }
    
      componentWillUnmount() {
        // Cleanup when the component unmounts
        this.carouselInstance.dispose();
      }
    
      handleWhatsAppClick() {
        
        const pieceTitle = encodeURIComponent(this.props.piece.title);
        const price = calculateDiscountedPrice(this.props.piece.price, this.props.piece.offer);
        
        window.open(`https://wa.me/+50763813932?text=Hola%20estoy%20interesado%20(a)%20en%20adquirir%20esta%20obra:%20${pieceTitle} Price: $ ${price}`);
      
      }
    
  
    render() {
      return (
        <>
                       <a href='#a' className="" onClick={this.handleShow}>
          <div class="uael-module-content uael-imgicon-wrap">
                <div class="uael-image" itemscope="" itemtype="http://schema.org/ImageObject">
                    <div class="uael-image-content elementor-animation-float">
       
                        <img loading="lazy" decoding="async" src={this.props.piece.img1} alt="" />
               
                    </div>
                </div>
         </div>
         </a>
  
          <div className={`modal fade ${this.state.show ? 'show' : ''}`} style={{ display: this.state.show ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog custom-modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <h5 className="modal-title">{this.props.title}</h5> */}
                  <button type="button" className="btn-close" onClick={this.handleClose}></button>
                </div>
                <div className="modal-body remove-borders-2">
                
                <div class="row">
                  <div class="col-7">

                  <div id={`carousel-${this.props.id}`} className="carousel slide" data-bs-ride="carousel" ref={this.carouselRef}>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={this.props.piece.img1} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src="images/floral-painting-3.png" className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src="images/floral-painting-3.png" className="d-block w-100" alt="..."/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-${this.props.id}`} data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target={`#carousel-${this.props.id}`} data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>

                  </div>
                  
                  <div class="col-5">
                  <br/>        
                      <h4 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-2 remove-borders" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">{this.props.piece.title}</h4>
                      <p class=" color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders text-left-sold" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">by Itza Morales</p>
                      <hr></hr>
                      <br/> 

                      <p class="uael-infobox-title-prefix elementor-inline-editing remove-borders" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"> 
                          {this.props.piece.offer === 0 ? 
                              <span><FormattedMessage id="store.modal.price" /> </span> 
                              :
                              <span class="red-percent">-{this.props.piece.offer}% </span>
                          } 
                          <b class="up-price" > ${calculateDiscountedPrice(this.props.piece.price, this.props.piece.offer)}</b>
                      </p>

                      {this.props.piece.offer === 0 ? 
                          <span> </span> 
                          :
                          <p class="uael-infobox-title-prefix elementor-inline-editing remove-borders " data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"> <FormattedMessage id="store.price.original" /> <span class="middle-line">${this.props.piece.price}</span> </p>

                      }        

                      <br/>      <br/>      
                      <h5 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-6 remove-borders" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="store.modal.size" /></h5>       
                    
                      <p class=" color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders text-left-sold" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"> {this.props.piece.size.width} x {this.props.piece.size.height}</p>
                    
                      <br/>      <br/> 
                      <h5 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-6 remove-borders" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="store.modal.materials" /></h5>       
                    

                      <p class=" color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders p-sold-left text-left-sold" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">{this.props.piece.medium} </p>

                      <br/>      <br/> 
                      <h5 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-6 remove-borders" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="store.modal.delivery" /></h5>       
                    
                      <p class=" color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders p-sold-left text-left-sold" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                      <FormattedMessage id="store.modal.delivery.desc" />
                      {/* For international purchases, please contact us directly for pricing and shipping information.     */}
                      </p>                             


                  </div>
               </div>

                </div>
                <div className="modal-footer">

                  <button type="button" className="uael-button-wrapper elementor-button-wrapper" onClick={this.handleClose}> <FormattedMessage id="store.modal.btn.close" /></button>
                 
                  <button type="button"
                   onClick={(e) => {
                   e.preventDefault();
                  
                   this.handleWhatsAppClick();
                   }}
                  >  <FormattedMessage id="store.modal.btn.contact" />   <i class="fab fa-whatsapp font-awosome-size"></i> </button>

                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
  

export default ShowDetails;
