import React from 'react';


import Contact from '../Components/Conteiners/Pages/Contact';
import SendEmail from '../Components/Animation/SendEmail';
import NetworkingError from '../Components/Animation/NetworkingError';
import Loading from '../Components/Animation/Loading';
import * as Config from './config';

class ContactService extends  React.Component {

  constructor(props) {
      super(props);

        this.state = { publishs: [] , navPage:[], name: '', lastName:'', email: '', phone:'', message: '', sendSuccess:false,sendError: true, actionSend:false, sendEmailLoading: false }
        this.appearFormPage = true;
        this.appearSuccessEmail = false;
        this.appearErrorPage = false;
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
      const id = event.target.id;

      switch(id) {
            case 'form_name':
                  this.setState({name: event.target.value});
              break;
            case 'form_lastname':
                  this.setState({lastName: event.target.value});
            case 'form_email':
                  this.setState({email: event.target.value});
              break;
            case 'form_phone':
                  this.setState({phone: event.target.value});
              break;
            case 'form_message':
                  this.setState({message: event.target.value});
              break;
            default:
                  alert('A name was submitted: ' + id);
      }
    }

   handleSubmit(event) {
      this.setState({actionSend: true});
      this.appearSuccessEmail = true;
      event.preventDefault();
      this.setState({sendEmailLoading: true});
      this.componentDidMount();
    }

  componentDidMount() {
       var _self = this; 
       window.scrollTo(0, 0);
       
      if(this.appearSuccessEmail){
         Config.instance.post(Config.BaseUrl + Config.PostEmail, {name: this.state.name, description: this.state.message, email: this.state.email, phone: "000-000"})
         .then(function (response) {
           
           _self.setState({sendSuccess: true});
           _self.setState({actionSend: true});
           _self.setState({sendEmailLoading: false});
          
         })
         .catch(function (error) {
           
             _self.setState({sendError: true});
             _self.setState({sendEmailLoading: false});

             _self.setState({sendSuccess: true});
             _self.setState({actionSend: true});
             _self.setState({sendEmailLoading: false});
         });
        }

  }


 render() {
  
   if(!this.state.actionSend)
       return (<Contact contactService = {this} />);
   else 
       return (<SendEmail/>);

 }
}

export default ContactService;
