import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation_lottie/second_loading.json'

class LoadingDownContent extends Component {

  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className="Content-st">

        <div class="container">


            <div class ="row mb-4 justify-content-center">
                <div class ="col-12 col-lg-5 col-md-6 col-sm-12 p-4 mb-5 mt-5 my-auto">
                  <Lottie  height={125}  width={125}  options={defaultOptions}/>
                  {/* <h2 class = "text-center ">Loading page</h2> */}
                  <p class = "text-gray text-center ">Please wait!</p>
             
                </div>
             </div>

        </div>






     </div>
    )
  }
}

export default LoadingDownContent
