import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation_lottie/69292-news.json'
import classNames from "classnames";

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { FormattedMessage } from "react-intl";


const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
var didSelectClass = classNames(
  'menu-item',
  'menu-item-type-post_type',
  'menu-item-object-page',
  'menu-item-18'
);

var selectedClass = classNames(
  'menu-item',
  'menu-item-type-post_type',
  'menu-item-object-page',
  'menu-item-18',
  'current-menu-item',
  'page_item page-item-11',
  'current_page_item',
  'menu-item-17'
);

class Header extends React.Component {

  constructor(props) {
    super(props);
    
    var path = window.location.pathname.replace('/','');

    this.state = {
      selectedPage: path, // Default selected page
    };

  }

  
 

  render(){

    return(
  
      <header class="site-header header-main-layout-1 ast-primary-menu-enabled ast-hide-custom-menu-mobile ast-builder-menu-toggle-icon ast-mobile-header-inline" id="masthead" itemtype="https://schema.org/WPHeader" itemscope="itemscope" itemid="#masthead">
                          
      <div id="ast-desktop-header" data-toggle-type="dropdown">
      <div class="ast-main-header-wrap main-header-bar-wrap ">
      <div class="ast-primary-header-bar ast-primary-header main-header-bar site-header-focus-item" data-section="section-primary-header-builder">
      <div class="site-primary-header-wrap ast-builder-grid-row-container site-header-focus-item ast-container" data-section="section-primary-header-builder">
      <div class="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
      <div class="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
      <div class="ast-builder-layout-element ast-flex site-header-focus-item" data-section="title_tagline">
      <div class="site-branding ast-site-identity" itemtype="https://schema.org/Organization" itemscope="itemscope">
      <span class="site-logo-img"> <Link to={'/' } class="custom-logo-link" rel="home">
        <img width="125" height="50" src="images/artist-logo-free-img.webp" class="" alt="Artist" decoding="async"/>
        </Link></span> </div>

      </div>
      </div>
      <div class="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
      <div class="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item" data-section="section-hb-menu-1">
      <div class="ast-main-header-bar-alignment">
        <div class="main-header-bar-navigation">
          <nav class="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item" id="primary-site-navigation-desktop" aria-label="Site Navigation" itemtype="https://schema.org/SiteNavigationElement" itemscope="itemscope"><div class="main-navigation ast-inline-flex"><ul id="ast-hf-menu-1" class="main-header-menu ast-menu-shadow ast-nav-menu ast-flex  submenu-with-border stack-on-mobile">
    

      <li id="menu-item-16" className={ this.state.selectedPage == 'Home' ?  selectedClass : didSelectClass}    onClick={() => this.setState({ selectedPage: 'Home' })}  ><Link to={'/' } class="menu-link">    <FormattedMessage id="header.home" /></Link></li>
      <li id="menu-item-17" className={ this.state.selectedPage == 'Galery' ?  selectedClass : didSelectClass}  onClick={() => this.setState({ selectedPage: 'Galery' })}   ><Link to={'/Galery' } class="menu-link"><FormattedMessage id="header.galery" /></Link></li>
      <li id="menu-item-18" className={ this.state.selectedPage == 'About' ?  selectedClass : didSelectClass}  onClick={() => this.setState({ selectedPage: 'About' })}   ><Link to={'/About' } class="menu-link"><FormattedMessage id="header.about" /></Link></li>
      <li id="menu-item-18" className={ this.state.selectedPage == 'Store' ?  selectedClass : didSelectClass}  onClick={() => this.setState({ selectedPage: 'Store' })}   ><Link to={'/Store' } class="menu-link"><FormattedMessage id="header.store" /> <i class="fas fa-shopping-cart"></i></Link></li>
     
      {/* <li id="menu-item-20" className={this.props.page == 'Contact' ?  selectedClass : didSelectClass} ><Link to={'/Contact' } class="menu-link">Contact</Link></li> */}
      
      </ul></div></nav></div></div> </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div class="ast-desktop-header-content content-align-flex-start ">
      </div>
      </div> 

      <div id="ast-mobile-header" class="ast-mobile-header-wrap " data-type="dropdown">
      <div class="ast-main-header-wrap main-header-bar-wrap">
      <div class="ast-primary-header-bar ast-primary-header main-header-bar site-primary-header-wrap site-header-focus-item ast-builder-grid-row-layout-default ast-builder-grid-row-tablet-layout-default ast-builder-grid-row-mobile-layout-default" data-section="section-primary-header-builder">
      <div class="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
      <div class="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
      <div class="ast-builder-layout-element ast-flex site-header-focus-item" data-section="title_tagline">
      <div class="site-branding ast-site-identity" itemtype="https://schema.org/Organization" itemscope="itemscope">
      <span class="site-logo-img"> <Link to={'/' } class="custom-logo-link" rel="home"><img width="151" height="25" src="images/artist-logo-free-img.webp" class="custom-logo astra-logo-svg" alt="Artist" decoding="async"/></Link></span> </div>

      </div>
      </div>
      <div class="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
      <div class="ast-builder-layout-element ast-flex site-header-focus-item" data-section="section-header-mobile-trigger">
      <div class="ast-button-wrap">
      <button type="button" class="menu-toggle main-header-menu-toggle ast-mobile-menu-trigger-fill" aria-expanded="false">
      <span class="screen-reader-text">Main Menu</span>
      <span class="mobile-menu-toggle-icon">
      <span class="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg class="ast-mobile-svg ast-menu-svg" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path></svg></span><span class="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg class="ast-mobile-svg ast-close-svg" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path></svg></span> </span>
      </button>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div class="ast-mobile-header-content content-align-flex-start ">
      <div class="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item" data-section="section-hb-menu-1">
      
      <div class="ast-main-header-bar-alignment"><div class="main-header-bar-navigation"><nav class="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item" id="primary-site-navigation-mobile" aria-label="Site Navigation" itemtype="https://schema.org/SiteNavigationElement" itemscope="itemscope"><div class="main-navigation ast-inline-flex"><ul id="ast-hf-menu-1" class="main-header-menu ast-menu-shadow ast-nav-menu ast-flex  submenu-with-border stack-on-mobile">
        
        
      <li  className={ this.state.selectedPage == 'Home' ?  selectedClass : didSelectClass}    onClick={() => this.setState({ selectedPage: 'Home' })}><Link to={'/' } class="menu-link"><FormattedMessage id="header.home" /></Link></li>
      <li  className={ this.state.selectedPage == 'Galery' ?  selectedClass : didSelectClass} onClick={() => this.setState({ selectedPage: 'Galery' })} ><Link to={'/Galery' } class="menu-link"><FormattedMessage id="header.galery" /></Link></li>
      <li className={this.state.selectedPage == 'About' ?  selectedClass : didSelectClass}  onClick={() => this.setState({ selectedPage: 'About' })} ><Link to={'/About' } class="menu-link"><FormattedMessage id="header.about" /></Link></li>
      <li className={this.state.selectedPage == 'Store' ?  selectedClass : didSelectClass}  onClick={() => this.setState({ selectedPage: 'Store' })} ><Link to={'/Store' } class="menu-link"><FormattedMessage id="header.store" /></Link></li>
    
      </ul></div></nav></div></div> </div>
      </div>
      </div>
      
      </header>
    
    )
  }

}

export default Header