import React, { Component } from 'react'
import * as DateController from '../../Helpers/DateController'

var imageIcon = require('../../assets/Image/icon3.png')
var logo = require('../../assets/Image/logo2.png')

class HeaderBlog extends React.Component {

  render(){
    
    const style = {
        backgroundImage: 'url(' + this.props.ImgUrl + ')',
        width: '100%',
        hight: '100%',
        backgroundrepeat: 'no-repeat !importan',
        backgroundsize: 'cover'
      };

      const style2 = {
        
        position: 'relative',
        minHeight: '70vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0,0,0,0.5)',
        backdropfilter: 'contrast(5) blur(100px)',

      };

      const style_h1 = {
       
       
      };

    return(

        <div class="m-0 shadow jumbotron-fluid container-fluid p-0" style={style}>
          <div class="container-fluid text-center" style={style2}>
          <div class="container-fluid text-center"  style={style_h1}>
          <h1 class="text-white text-uppercase  border-text-color-2 noto">{this.props.title}</h1>
          <p class="color-title-3 font-size-header noto mb-5">{DateController.timeAgo.format(new Date(this.props.date) - 2 * 24 * 60 * 60 * 1000, 'twitter')}<span class="color-third"> | </span> by {this.props.autor}</p>
          
      </div>
        
          
        </div>
      </div>
    )
  }

}

export default HeaderBlog