


export function calculateDiscountedPrice(originalPrice, discountPercentage) {
    // Calculate the discounted amount
    const discountAmount = originalPrice * (discountPercentage / 100);
    
    // Calculate the discounted price
    let discountedPrice = originalPrice - discountAmount;
    
    // Round the discounted price to two decimal places
    discountedPrice = Number(discountedPrice.toFixed(2));
    
    // Return the discounted price
    return discountedPrice;
}