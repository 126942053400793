import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FormattedMessage } from "react-intl";


class FooterStandar extends Component {
  render(){return (
    <div className="Footer-st m-0">

<footer class="site-footer" id="colophon" itemtype="https://schema.org/WPFooter" itemscope="itemscope" itemid="#colophon">
    <div class="site-primary-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-3-equal ast-builder-grid-row-tablet-3-equal ast-builder-grid-row-mobile-full ast-footer-row-stack ast-footer-row-tablet-stack ast-footer-row-mobile-stack" data-section="section-primary-footer-builder">
        <div class="ast-builder-grid-row-container-inner">
            <div class="ast-builder-footer-grid-columns site-primary-footer-inner-wrap ast-builder-grid-row">
                <div class="site-footer-primary-section-1 site-footer-section site-footer-section-1">
                    <aside class="footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner" data-section="sidebar-widgets-footer-widget-1" aria-label="Footer Widget 1">
                        <section id="text-3" class="widget widget_text">
                            <h2 class="widget-title">   <FormattedMessage id="footer.contact" /> </h2>
                            <div class="textwidget">
                                <p>itzamorales1024@gmail.com<br /></p>
                            </div>
                        </section>
                    </aside>
                </div>
                <div class="site-footer-primary-section-2 site-footer-section site-footer-section-2">
                    <aside class="footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner" data-section="sidebar-widgets-footer-widget-2" aria-label="Footer Widget 2">
                        <section id="text-4" class="widget widget_text">
                            <h2 class="widget-title">  <FormattedMessage id="footer.address" /> </h2>
                            <div class="textwidget">
                                <p>Panamá, Chiriqui,<br />San Felix</p>
                            </div>
                        </section>
                    </aside>
                </div>
                <div class="site-footer-primary-section-3 site-footer-section site-footer-section-3">
                    <aside class="footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner" data-section="sidebar-widgets-footer-widget-3" aria-label="Footer Widget 3">
                        <section id="text-5" class="widget widget_text">
                            <h2 class="widget-title"> <FormattedMessage id="footer.social" /> </h2>
                            <div class="textwidget"></div>
                        </section>
                    </aside>
                    <div class="ast-builder-layout-element ast-flex site-footer-focus-item" data-section="section-fb-social-icons-1">
                        <div class="ast-footer-social-1-wrap ast-footer-social-wrap">
                            <div class="footer-social-inner-wrap element-social-inner-wrap social-show-label-false ast-social-color-type-custom ast-social-stack-none ast-social-element-style-filled">
                                
                                <a href="https://www.facebook.com/profile.php?id=61550495997192" aria-label="Facebook" target="_blank" rel="noopener noreferrer" class="ast-builder-social-element ast-inline-flex ast-facebook footer-social-item">
                                  <span class="ahfb-svg-iconset ast-inline-flex svg-baseline">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z">
                                      </path>
                                    </svg>
                                  </span>
                                </a>
                                
                                <a href="https://www.instagram.com/jnmitzas.arts?fbclid=IwAR29936U4OEn_TtFuO2qWhPZNPjVLlaK_KZxyviKYdeGwoHGJQQxloXsdCw" aria-label="Instagram" target="_blank" rel="noopener noreferrer" class="ast-builder-social-element ast-inline-flex ast-instagram footer-social-item">
                                  <span class="ahfb-svg-iconset ast-inline-flex svg-baseline">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z">
                                      </path>
                                    </svg>
                                  </span>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="site-below-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-full ast-builder-grid-row-tablet-full ast-builder-grid-row-mobile-full ast-footer-row-inline ast-footer-row-tablet-inline ast-footer-row-mobile-inline" data-section="section-below-footer-builder">
        <div class="ast-builder-grid-row-container-inner">
            <div class="ast-builder-footer-grid-columns site-below-footer-inner-wrap ast-builder-grid-row">
                <div class="site-footer-below-section-1 site-footer-section site-footer-section-1">
                    <div class="ast-builder-layout-element ast-flex site-footer-focus-item ast-footer-copyright" data-section="section-footer-builder">
                        <div class="ast-footer-copyright">
                            <p>Copyright © 2024 Itza's Art</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>


    </div>
  );}
}

export default FooterStandar;
