import React from 'react';


import Publish from '../Components/Conteiners/Pages/Publish';
import LoadingPublish from '../Components/Animation/LoadingPublish';
import HeaderError from '../Components/Animation/HeaderError';
import BuildingPage from '../Components/Animation/BuildingPage';
import * as Config from './config';
import Header from '../../src/Components/Conteiners/Header'

class PublishsService extends  React.Component {
  // Add locale-specific relative date/time formatting rules.
  // TimeAgo.addLocale(en)
  // Create relative date/time formatter.
 /// Use https://reacttraining.com/react-router/native/example/Basic 
  constructor(props) {
      super(props);

        this.state = { publishs: [] , navPage:[]}
        this.nextPage = '0';
        this.titlePage = '1';
  }

  Header = () => {
      return Header;
  }

  nexContent = (event,title) => {
      this.nextPage = event.target.value;
      this.titlePage = title;
      this.componentDidMount();
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    Config.instance.get(Config.BaseUrl + Config.GetNextPublishs + this.nextPage + '/' + this.titlePage)
        .then(res => {
              const coins = res.data;
             // alert( JSON.stringify(coins.listNextContent));
              this.setState({ publishs: coins.content , navPage: coins.listNextContent});
     }).catch(function(error) {
       
             this.setState({load_failed: true});
       }.bind(this));

  }


 render() {
   if(!Config.onDeveloper){
     if(this.state.publishs.length > 0)
         return (<Publish publishs = {this} />);
     else if(this.state.load_failed)
        return (<HeaderError/>);
     else
        return (<LoadingPublish/>);
   }else
        return (<BuildingPage/>);
 }
}

export default PublishsService;
