import React, { Component } from 'react'
import { Helmet } from "react-helmet";

class MetaTag extends React.Component {

  render(){

    return(
<div>
    <Helmet>
        <title>{this.props.title}</title>
    
        <meta name="title" content={this.props.title} data-react-helmet="true"/>
         <meta
           name ="description"
           content = {this.props.desc}
          />
         <meta
           name = "keywords"
           content = "HTML,CSS,XML,JavaScript,Programming,Word,Blog Code,Anohter Programmer,Code,Devops,Java,C#"
          />
        <meta
           name = "author"
           content = {this.props.autor}
          />
         <meta 
            name="msapplication-TileImage" 
            //content = {this.props.img}
            content="icon/ms-icon-144x144.png"
          />
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="theme-color" content="#ffffff"/>
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </Helmet>
 </div>
    )
  }

}

export default MetaTag