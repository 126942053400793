export function getLanguage(locale) {

    const region = locale.split('-')[0]; 
    /*
    const spanishLocales = [
        "es-ES", // Spanish (Spain)
        "es-MX", // Spanish (Mexico)
        "es-AR", // Spanish (Argentina)
        "es-CO", // Spanish (Colombia)
        "es-CL", // Spanish (Chile)
        "es-PE", // Spanish (Peru)
        "es-VE", // Spanish (Venezuela)
        "es-CU", // Spanish (Cuba)
        "es-EC", // Spanish (Ecuador)
        "es-GT", // Spanish (Guatemala)
        "es-DO", // Spanish (Dominican Republic)
        "es-BO", // Spanish (Bolivia)
        "es-HN", // Spanish (Honduras)
        "es-SV", // Spanish (El Salvador)
        "es-NI", // Spanish (Nicaragua)
        "es-PR", // Spanish (Puerto Rico)
        "es-UY", // Spanish (Uruguay)
        "es-PY", // Spanish (Paraguay)
        "es-PA"  // Spanish (Panama)
        // Add more Spanish locales as needed
        // Note: There may be additional Spanish locales not listed here
    ];
    */
    
    // alert(region);

    if (region === "es") {
        return "Spanish";
    } else {
        return "English";
    }
}
