import React from 'react'
import NumberCounter from 'number-counter'; 
import Header from '../../Conteiners/Header'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MetaTag from '../../Conteiners/MetaReact'

import ScrollAnimation from 'react-animate-on-scroll';

import artworks from '../../../assets/json/artworks.json'

import ShowDetails from '../../Modals/ShowDetails'

import { FormattedMessage,useIntl } from "react-intl";

import { calculateDiscountedPrice } from '../../../Helpers/MathHelper';
import SharedBlog from '../../Conteiners/ShareBlog'

import langSpanish from '../../../../src/lang/es.json';

import langEnglish from '../../../../src/lang/en.json';

import {getLanguage} from '../../../../src/Helpers/LenguajesHelper'

const urlSite = window.location.href; 

const phoneWhatsapp = "+50763813932";

const mainSite = "+50763813932";

const locale = navigator.language;

const lang =  getLanguage(locale) === 'Spanish' ? langSpanish : langEnglish;

class Details extends React.Component {

  constructor(props) {
    super(props);
    
    this.carouselRef = React.createRef(); // Create a ref for the carousel
    
    // Extracting the search string from the location object
    const queryString = this.props.location.search;

    // Parsing the search string to get the query parameters
    const searchParams = new URLSearchParams(queryString);

    // Getting the value of the 'title' parameter
    const title = searchParams.get('title').split('-').join(' ');

    const selectedPiece = this.findArtworkByTitle(title);
    const originalPieces = this.getArtworksByCategory(selectedPiece.category);
    const shuffledPieces = this.shuffleArray([...originalPieces]);

     // Now you can use whatsappLink wherever needed, for example, in an anchor tag:
    // document.getElementById("whatsappButton").href = whatsappLink;

    this.state_arts_random = {
      shuffledPieces: shuffledPieces,
      originalPieces: originalPieces,
      selected: selectedPiece,
  };

    this.state_arts = artworks;

    this.state = {
      isModalOpen: false,
      selectedProduct: {
        imageData: 'image-url.jpg',
        productName: 'Product Name',
        productDescription: 'Product Description',
        productPrice: '$10.00'
      }
    };
   // this.shuffleAndSetPieces();
    
  }

  // Function to find artwork by title
 findArtworkByTitle(title) {
    return artworks.pieces.find(piece => piece.title === title);
}

// Function to get all artworks by category
 getArtworksByCategory(category) {
    return artworks.pieces.filter(piece => piece.category === category);
}

  shuffleAndSetPieces() {
    const originalPieces = this.state_arts.pieces; // Using state_arts instead of artworks directly
    const shuffledPieces = this.shuffleArray([...originalPieces]);
    this.state_arts_random({ shuffledPieces, originalPieces });
}

shuffleArray(array) {
  console.log("Shuffling array...");
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      console.log(j);
  }

  return shuffledArray;
}

handleWhatsAppClick() {
        
    const pieceTitle = encodeURIComponent(this.selectedPiece.title);
    const price = calculateDiscountedPrice(this.selectedPiece.price, this.selectedPiece.offer);
    
    // window.open(`https://wa.me/+50763813932?text=Hola%20estoy%20interesado%20(a)%20en%20adquirir%20esta%20obra:%20${pieceTitle} Price: $ ${price}`);
  
  }
 

  componentWillUnmount() {
    // Cleanup when the component unmounts
    this.carouselInstance.dispose();
  }


  componentDidMount() {
    window.scrollTo(0, 0);
      // Start the carousel when the component mounts
      this.carouselInstance = new window.bootstrap.Carousel(this.carouselRef.current, {
        interval: 15000, // Specify your desired interval here
      });
  }
  
    render(){

      return (

        <div class="Content-st">

        <div class="hfeed site" id="page">


             <MetaTag title = {this.state_arts_random.selected.title} img = {this.state_arts_random.selected.img1} desc = 'store.section.offert.desc'/>

             <div id="content" class="site-content">
             <div class="ast-container">
             <div id="primary" class="content-area primary">

              <main id="main" class="s">
                  
                  <article class="post-11 page type-page status-publish ast-article-single" id="post-11" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                      <header class="entry-header ast-no-title ast-header-without-markup"></header> 

                      <div class="entry-content clear" itemprop="text">

                         <div data-elementor-type="wp-post" data-elementor-id="11" class="elementor elementor-11">
             
            <section class="elementor-section elementor-top-section elementor-element elementor-element-69a61f19 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="69a61f19" data-element_type="section" data-settings='{"background_background":"classic"}'>

                    <div class="elementor-background-overlay"></div>
                    <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15912662" data-id="15912662" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-8ed6ae0 elementor-widget elementor-widget-heading" data-id="8ed6ae0" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                    {/* <h1 class="elementor-heading-title elementor-size-default"> <FormattedMessage id="store.section.title" /> </h1> */}
                    </div>
                    </div>
                    </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7dd1dc0b" data-id="7dd1dc0b" data-element_type="column">
                    <div class="elementor-widget-wrap">
                    </div>
                    </div>
                    </div>

            </section>

            <section class="elementor-section elementor-inner-section elementor-element elementor-element-9097030 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9097030" data-element_type="section">
                
                <div class="elementor-container elementor-column-gap-default">

                <div class="row">
                  <div class="col-12 col-lg-7">
                  <br/>      <br/>                <br/>      
                  <div id={`carousel-1`} className="carousel slide" data-bs-ride="carousel" ref={this.carouselRef}>
                      <div className="carousel-inner">

                      {this.state_arts_random.selected.img2 === "GROUP" ? 

                      this.state_arts_random.selected.images.map((img, index) => (

                            <div className="carousel-item ">
                            <img src={img} className="d-block w-100" alt="..."/>
                            </div>
                        ))
                        : 
                        <span> </span> 
                        }
  
                        <div className="carousel-item active">
                          <img src={this.state_arts_random.selected.img1} className="d-block w-100" alt="..."/>
                        </div>

                        {this.state_arts_random.selected.img2 != "GROUP" ? 

                            <div className="carousel-item ">
                            <img src={this.state_arts_random.selected.img2} className="d-block w-100" alt="..."/>
                            </div>
                        : 
                        <span> </span> 
                        }

                    

                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-1`} data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target={`#carousel-1`} data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>

                  </div>
                  
                  <div class="col-12 col-lg-5 ">
                  <br/>      <br/>                <br/> 
                      <h4 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-2 remove-borders padding-details" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">{this.state_arts_random.selected.title}</h4>
                      <p class=" color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders text-left-sold padding-details"  data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">by Itza Morales</p>
                      <hr></hr>
                      <br/> 

                      <p class="uael-infobox-title-prefix elementor-inline-editing remove-borders padding-details" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"> 
                         
                          {this.state_arts_random.selected.offer === 0 ? 
                              <span><FormattedMessage id="store.modal.price" /> </span> 
                              :
                              this.state_arts_random.selected.price != 'SOLD'?
                              <span class="red-percent">-{this.state_arts_random.selected.offer}% </span>
                              : <span></span>
                          } 

                          {
                            this.state_arts_random.selected.price != 'SOLD'?
                            <b class="up-price" > ${calculateDiscountedPrice(this.state_arts_random.selected.price, this.state_arts_random.selected.offer)}</b>
                            :  <b class="red-percent" > {this.state_arts_random.selected.price}</b>
                          }
                     
                      </p>

                      {this.state_arts_random.selected.offer === 0 ? 
                          <span> </span> 
                          :

                          
                            this.state_arts_random.selected.price != 'SOLD'?
                            <p class="uael-infobox-title-prefix elementor-inline-editing remove-borders padding-details" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"> 
                         
                            <FormattedMessage id="store.price.original" /> <span class="middle-line-d">${this.state_arts_random.selected.price}
                          
                            </span> </p>
                            : <span></span>
                          
                      

                      }        

                      <br/>      <br/>      
                     
                      <h5 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-6 remove-borders padding-details" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="store.modal.size" /></h5>       
                    
                      {this.state_arts_random.selected.img2 != "GROUP" ? 

                            <p class=" color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders text-left-sold padding-details" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic"> {this.state_arts_random.selected.size.width} x {this.state_arts_random.selected.size.height}</p>
                                                
                            : 

                            <p style={{ whiteSpace: "pre-line" }} class="padding-details">
                                {this.state_arts_random.selected.description.split("<br/>").join("\n")}
                            </p>
                                             
                       }
                     
                      <br/>      
                      <h5 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-6 remove-borders padding-details" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="store.modal.materials" /></h5>       
                    

                      <p class=" color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders p-sold-left text-left-sold padding-details" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">{this.state_arts_random.selected.medium} </p>

                      <br/>      
                      <h5 class="  text-left-sold uael-infobox-title elementor-inline-editing color-about-store-6 remove-borders padding-details" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="store.share" /></h5>       
                    

                      <div class="conteiner padding-details">

                <SharedBlog
                  title={this.state_arts_random.selected.title}
                  img={this.state_arts_random.selected.img1}
                  desc={this.state_arts_random.selected.medium  + "-" + this.state_arts_random.selected.category + "Size:" + this.state_arts_random.selected.size.width + "X" + this.state_arts_random.selected.size.height} 
                  autor={"Itza Morales"}
                  id={this.state_arts_random.selected.title}
                  size={40}
                  rounded={false}
                />

              </div>


                      <br/>     
                  
                      {
                            this.state_arts_random.selected.price != 'SOLD'?
                            <h5 class=" padding-details text-left-sold uael-infobox-title elementor-inline-editing color-about-store-6 remove-borders" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="store.modal.delivery" /></h5>       
                    
                            
                            :  <br/>
                          }
                      
                      {
                            this.state_arts_random.selected.price != 'SOLD'?
                            <p class="padding-details color-modal-p-black uael-infobox-title-prefix elementor-inline-editing remove-borders p-sold-left text-left-sold" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                     
                            <FormattedMessage id="store.modal.delivery.desc" />
                            {/* For international purchases, please contact us directly for pricing and shipping information.     */}
                            </p>
                            :  <br/>
                          }
      


                      <br/>    
                      <div class="row">

                           

                           <div class="col-12 col-lg-12  center-text-mv whatsapp-button-container">

                           {
                            this.state_arts_random.selected.price != 'SOLD'?
                            <a  type='button' class="whatsapp-button" target="_blank"  href = {`https://wa.me/${phoneWhatsapp}?text=${lang['detail.i.want'].replace("[nombre de la obra]", encodeURIComponent(this.state_arts_random.selected.title)).replace("[URL]", '') + urlSite}`}  >
                            
                            <FormattedMessage id="store.modal.btn.contact" />   <i class="fab fa-whatsapp font-awosome-size"></i>
                          
                          </a>
                            :  <br/>
                          }

                           
                            
                           </div>
                        
                      </div>                             


                  </div>
               </div>
                      
            </div>
           </section>

           <section class="elementor-section elementor-inner-section elementor-element elementor-element-9097030 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9097030" data-element_type="section">
                <div class="">
                    
                    <div class="" data-id="dc4bc25" data-element_type="column">
                        <div class="">
                        <div class="">
                                        {/* Insert Category  */}

                                        
                                
                                        <h3 class=" color-about-store-3" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"> <FormattedMessage id="store.section.sub.title.more" /> </h3>
                                        <p class="uael-infobox-title-prefix elementor-inline-editing color-about-store-5" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">

                                            <FormattedMessage id="store.section.offert.desc" />

                                        </p>
                                    

                                </div>
                        </div>
                        
                    </div>

                    
                    
                </div>
                
            </section>

        <section class="elementor-section elementor-inner-section elementor-element elementor-element-9097030 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9097030" data-element_type="section">
                
             <div class="elementor-container elementor-column-gap-default">
                
              <div class="boxes-con">                   

                 {this.state_arts_random.shuffledPieces.map((piece, index) => (
                         <div class="boxes elementor-animation-float">
         <Link to={'/Details?title=' + piece.title.split(' ').join('-')} target="_blank" className="" class="remove-link card">
  
                     
               
                  

<div class="uael-module-content uael-imgicon-wrap ">
      <div class="uael-image" itemscope="" itemtype="http://schema.org/ImageObject">
          <div class="uael-image-content ">

              <img loading="lazy" decoding="async" src={piece.img1} alt="" />
    
          </div>
      </div>
 </div>



 <div class="card_content">
    <div class="card_title color-about-store-2">{piece.title}</div>
    <div class="card_desc">


                               
                                    
                             

                                    {piece.offer === 0 ? 
                                        <span></span> 
                                        :
                                        piece.price != 'SOLD'? 
                                          <p class="uael-infobox-title-prefix elementor-inline-editing remove-borders " data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">
                                           
                                             <FormattedMessage id="store.price.original" /> <span class="middle-line">${piece.price}</span> 
                                             
                                             </p>
                                        :<span></span> 
                                    } 

                                    {piece.price != 'SOLD'? 
                                    
                                           <b class="uael-infobox-title-prefix elementor-inline-editing remove-borders pb-0" >
                                              {piece.offer === 0 ? 
                                                <FormattedMessage id="store.price.original"/> 
                                                :<FormattedMessage id="store.price.offer" />
                                              }
                                      
                                                ${calculateDiscountedPrice(piece.price, piece.offer)}
                                             
                                             </b>
                                        
                                          :  <b class="red-percent" > {piece.price}</b>
                                        
                                    }

                                  &nbsp;&nbsp;
                                   {piece.offer === 0 ? 
                                        <span></span> 
                                        :
                                        piece.price != 'SOLD'? 
                                        <span class="red-percent">
                                          
                                          -{piece.offer}% &nbsp;
                                          
                                           </span>
                                           :    <span></span> 
                                    }
    </div>
  </div>
  <div class="card_bottom_shadow"></div>
            
                  </Link>
        
                  </div>

          
   
        ))}


            </div>
         </div>
        </section>

        


        <section class="elementor-section elementor-top-section elementor-element elementor-element-981148e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="981148e" data-element_type="section" data-settings='{"background_background":"classic"}'>
                                    
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-ec21525" data-id="ec21525" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-b7661ca uael-ins-normal elementor-widget elementor-widget-uael-image-gallery" data-id="b7661ca" data-element_type="widget" data-settings='{"slides_to_show":3,"column_gap":{"unit":"px","size":5,"sizes":[]},"slides_to_show_tablet":3,"slides_to_show_mobile":2,"slides_to_scroll":1,"slides_to_scroll_tablet":1,"slides_to_scroll_mobile":1,"column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]}}'>
                                                    <div class="elementor-widget-container">
                                                      
                                                        <img decoding="async" width="668" height="532" src="images/ocean_image.jpg" class="attachment-medium_large size-medium_large wp-image-77" alt="" srcset="images/ocean_image.jpg 768w, images/ocean_image.jpg 300w, images/ocean_image.jpg 850w" sizes="(max-width: 768px) 100vw, 768px"/>
                                                      
                                                        <div class="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                            <div data-image_carousel='{"slidestoshow":3,"slidestoscroll":1,"autoplayspeed":3000,"autoplay":true,"infinite":true,"pauseonhover":true,"speed":500,"arrows":false,"dots":false,"rtl":false,"responsive":[{"breakpoint":1024,"settings":{"slidestoshow":3,"slidestoscroll":1}},{"breakpoint":767,"settings":{"slidestoshow":2,"slidestoscroll":1}}]}' class="uael-img-gallery-wrap uael-img-carousel-wrap uael-nav-none" data-filter-default="All">
                                                                <div class="uael-grid-item uael-img-gallery-item-1">
                                                                    <div class="uael-grid-item-content">
                                                                        <figure class="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                            <div class="uael-grid-img-thumbnail uael-ins-target">
                                                                                <img decoding="async" width="768" height="632" src="images/frame4-free-img-1-768x632.png" class="attachment-medium_large size-medium_large wp-image-77" alt="" srcset="images/frame4-free-img-1-768x632.png 768w, images/frame4-free-img-1-300x247.png 300w, images/frame4-free-img-1.png 850w" sizes="(max-width: 768px) 100vw, 768px"/>
                                                                            </div>
                                                                            <div class="uael-grid-img-overlay"></div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-eaed9f6 container-2342" data-id="eaed9f6" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-87fc296 elementor-widget elementor-widget-uael-infobox" data-id="87fc296" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="uael-module-content uael-infobox uael-infobox-left uael-infobox-link-type-button">
                                                            <div class="uael-infobox-left-right-wrap">
                                                                <div class="uael-infobox-content">
                                                                    <div class="uael-infobox-title-wrap">
                                                                        <h3 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="about.section.popular.picture" /></h3>
                                                                    </div>
                                                                    <div class="uael-infobox-text-wrap">
                                                                        <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced"><FormattedMessage id="about.section.popular.about" /></div>
                                                                        <div class="uael-button-wrapper elementor-button-wrapper">
                                                                        <br></br> 
                                                                             <Link to={'/Store' } class="elementor-button-link elementor-button elementor-size-sm">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-align-icon-right elementor-button-icon"></span>
                                                                                  
                                                                                    <span class="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none"><FormattedMessage id="btn.more" /></span>
                                                                                </span>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
  
                                </section>
                        </div>

                       </div>

              </article>
              </main>
              </div>
              </div> 
              </div>

        </div>

  </div>
)
    }
  
}

export default Details


