import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation_lottie/loading _2.json'
import Header from '../../Components/Conteiners/Header' 

class LoadingPost extends Component {

  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className="Content-st mb-5">

        {/* <Header/> */}

        <div class="container mb-5">


        <div class ="row mb-4 justify-content-center mb-5 mt-5 mb-5">
                <div class ="col-12 col-lg-5 col-md-6 col-sm-12 p-4 mb-5 mt-5 my-auto">
                  <Lottie options={defaultOptions}/>
                  <h1 class = "text-center ">Loading post...</h1>
                   <p class = "text-gray text-center mb-5">Please wait!</p>
             
                </div>
             </div>

        </div>






     </div>
    )
  }
}

export default LoadingPost
