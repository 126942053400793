import React from 'react'
import NumberCounter from 'number-counter'; 
import Header from '../../Conteiners/Header'
import HeaderAbout from '../../Conteiners/HeaderAbout'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MetaTag from '../../Conteiners/MetaReact'
import { FormattedMessage,useIntl } from "react-intl";
import ScrollAnimation from 'react-animate-on-scroll';
// import '../../../assets/css/main.pages.css'

class Galery extends React.Component {

    
  componentDidMount() {
    window.scrollTo(0, 0);
    
  }
  
    render(){
 

      return (

        <div class="Content-st">

              <MetaTag title = "header.galery"  pages = "OK" desc = 'galery.section.catgory.one.desc' />
              
              <div class="hfeed site" id="page">

            
                   
                   <div id="content" class="site-content">
                   <div class="ast-container">
                   <div id="primary" class="content-area primary">

                    <main id="main" class="s">
                        
                        <article class="post-11 page type-page status-publish ast-article-single" id="post-11" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                            <header class="entry-header ast-no-title ast-header-without-markup"></header> 

                            <div class="entry-content clear" itemprop="text">

                               <div data-elementor-type="wp-post" data-elementor-id="11" class="elementor elementor-11">
                   
                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-69a61f19 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="69a61f19" data-element_type="section" data-settings='{"background_background":"classic"}'>

                                                    <div class="elementor-background-overlay"></div>
                                                    <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15912662" data-id="15912662" data-element_type="column">
                                                    <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-8ed6ae0 elementor-widget elementor-widget-heading" data-id="8ed6ae0" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                    <h1 class="elementor-heading-title elementor-size-default">    <FormattedMessage id="galery.section.title" /></h1> </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7dd1dc0b" data-id="7dd1dc0b" data-element_type="column">
                                                    <div class="elementor-widget-wrap">
                                                    </div>
                                                    </div>
                                                    </div>
                                                    
                                    </section>
                                    
                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-981148e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="981148e" data-element_type="section" data-settings='{"background_background":"classic"}'>
                                            <div class="elementor-container elementor-column-gap-default">
                                                <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-3b9abc0" data-id="3b9abc0" data-element_type="column">
                                                    <div class="elementor-widget-wrap elementor-element-populated">
                                                        <div class="elementor-element elementor-element-7bf16af elementor-widget elementor-widget-uael-infobox" data-id="7bf16af" data-element_type="widget" data-widget_type="uael-infobox.default">
                                                            <div class="elementor-widget-container">
                                                                <div class="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                                                                    <div class="uael-infobox-left-right-wrap">
                                                                        <div class="uael-infobox-content">
                                                                            <div class="uael-infobox-title-wrap">
                                                                                <h2 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="galery.section.catgory.one.title" /></h2>
                                                                            </div>
                                                                            <div class="uael-infobox-text-wrap">
                                                                                <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                                                                <FormattedMessage id="galery.section.catgory.one.desc" />
                                                                                </div>
                                                                                
                                                                                <div class="uael-button-wrapper elementor-button-wrapper">

                                                                                <Link to={'/Store?category=Abstracto'} class="elementor-button-link  elementor-button elementor-size-sm">
                                                                                        <span class="elementor-button-content-wrapper">
                                                                                            <span class="elementor-align-icon-right elementor-button-icon"></span>
                                                                                            <span class="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none"> <FormattedMessage id="btn.more" /></span>
                                                                                        </span>
                                                                                    </Link>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-d8c3fb8" data-id="d8c3fb8" data-element_type="column">
                                                    <div class="elementor-widget-wrap elementor-element-populated">
                                                        <section class="elementor-section elementor-inner-section elementor-element elementor-element-c99d480 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c99d480" data-element_type="section">
                                                            <div class="elementor-container elementor-column-gap-default">
                                                                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-38fd889" data-id="38fd889" data-element_type="column">
                                                                    <div class="elementor-widget-wrap elementor-element-populated">
                                                                        <div class="elementor-element elementor-element-1abd3c6 elementor-widget elementor-widget-image" data-id="1abd3c6" data-element_type="widget" data-widget_type="image.default">
                                                                            <div class="elementor-widget-container">
                                                                                <img fetchpriority="high" decoding="async" width="850" height="700" src="images/frame4-free-img.webp" class="elementor-animation-float attachment-large size-large wp-image-76" alt="" sizes="(max-width: 850px) 100vw, 850px"/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-element elementor-element-811bdd2 elementor-widget elementor-widget-image" data-id="811bdd2" data-element_type="widget" data-widget_type="image.default">
                                                                            <div class="elementor-widget-container">
                                                                                <img decoding="async" width="850" height="700" src="images/frame4-free-img-1.webp" class="elementor-animation-float attachment-large size-large wp-image-77" alt="" sizes="(max-width: 850px) 100vw, 850px"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f879bcb" data-id="f879bcb" data-element_type="column">
                                                                    <div class="elementor-widget-wrap elementor-element-populated">
                                                                        <div class="elementor-element elementor-element-23e3e18 elementor-widget elementor-widget-image" data-id="23e3e18" data-element_type="widget" data-widget_type="image.default">
                                                                            <div class="elementor-widget-container">
                                                                                <img decoding="async" width="700" height="850" src="images/frame2-free-img.webp" class="elementor-animation-float attachment-large size-large wp-image-443" alt=""  sizes="(max-width: 700px) 100vw, 700px"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                    </section>

                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-d50aa96 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d50aa96" data-element_type="section" data-settings='{"background_background":"classic"}'>

                                            <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-c5264f2" data-id="c5264f2" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-837ae6a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="837ae6a" data-element_type="section">
                                            <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-765d2c9" data-id="765d2c9" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-68fd308 elementor-widget elementor-widget-image" data-id="68fd308" data-element_type="widget" data-widget_type="image.default">
                                            <div class="elementor-widget-container">
                                            <img loading="lazy" decoding="async" width="850" height="700" src="images/floor_balck_12.jpeg" class="elementor-animation-float attachment-large size-large wp-image-433" alt="" sizes="(max-width: 850px) 100vw, 850px"/> </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4ea1338 elementor-widget elementor-widget-image" data-id="4ea1338" data-element_type="widget" data-widget_type="image.default">
                                            <div class="elementor-widget-container">
                                            <img loading="lazy" decoding="async" width="850" height="700" src="images/floral-painting.webp" class="elementor-animation-float attachment-large size-large wp-image-434" alt="" sizes="(max-width: 850px) 100vw, 850px"/> </div>
                                            </div>
                                            </div>
                                            </div>
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e977801" data-id="e977801" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-7577dad elementor-widget elementor-widget-image" data-id="7577dad" data-element_type="widget" data-widget_type="image.default">
                                            <div class="elementor-widget-container">
                                            <img loading="lazy" decoding="async" width="700" height="850" src="images/white_img.webp" class="elementor-animation-float attachment-large size-large wp-image-435" alt="" sizes="(max-width: 700px) 100vw, 700px"/> </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </section>

                                            </div>
                                            </div>
                                            <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1068e28" data-id="1068e28" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-3e39552 elementor-widget elementor-widget-uael-infobox" data-id="3e39552" data-element_type="widget" data-widget_type="uael-infobox.default">
                                            <div class="elementor-widget-container">
                                            <div class="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                                            <div class="uael-infobox-left-right-wrap">
                                            <div class="uael-infobox-content">
                                            <div class="uael-infobox-title-wrap"><h2 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="galery.section.catgory.two.title" /></h2></div> <div class="uael-infobox-text-wrap">
                                            <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                            <FormattedMessage id="galery.section.catgory.two.desc" /> </div>
                                            <div class="uael-button-wrapper elementor-button-wrapper">
                                            <Link  to={'/Store?category=Floral'} class="elementor-button-link  elementor-button elementor-size-sm">
                                            <span class="elementor-button-content-wrapper">
                                            <span class="elementor-align-icon-right elementor-button-icon">
                                            </span>
                                            <span class="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none"><FormattedMessage id="btn.more" /></span>
                                            </span>
                                            </Link>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>

                                    </section>

                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-981148e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="981148e" data-element_type="section" data-settings='{"background_background":"classic"}'>

                                        <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-3b9abc0" data-id="3b9abc0" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-7bf16af elementor-widget elementor-widget-uael-infobox" data-id="7bf16af" data-element_type="widget" data-widget_type="uael-infobox.default">
                                        <div class="elementor-widget-container">
                                        <div class="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                                        <div class="uael-infobox-left-right-wrap">
                                        <div class="uael-infobox-content">
                                        <div class="uael-infobox-title-wrap"><h2 class="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic"><FormattedMessage id="galery.section.catgory.three.title" /></h2></div> <div class="uael-infobox-text-wrap">
                                        <div class="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                        
                                        
                                        <FormattedMessage id="galery.section.catgory.three.desc" />

                                        </div>
                                        <div class="uael-button-wrapper elementor-button-wrapper">
                                        <Link to={'/Store?category=Various'} class="elementor-button-link  elementor-button elementor-size-sm">
                                        <span class="elementor-button-content-wrapper">
                                        <span class="elementor-align-icon-right elementor-button-icon">
                                        </span>
                                        <span class="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none"><FormattedMessage id="btn.more" /></span>
                                        </span>
                                        </Link>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-d8c3fb8" data-id="d8c3fb8" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                        <section class="elementor-section elementor-inner-section elementor-element elementor-element-c99d480 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c99d480" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-38fd889" data-id="38fd889" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-1abd3c6 elementor-widget elementor-widget-image" data-id="1abd3c6" data-element_type="widget" data-widget_type="image.default">
                                        <div class="elementor-widget-container">


                                        <img decoding="async" width="850" height="700" src="images/landscapes_1.webp" class="elementor-animation-float attachment-large size-large wp-image-77" alt="" srcset="images/landscapes_1.webp 850w, images/landscapes_1.webp 300w, images/landscapes_1.webp 768w" sizes="(max-width: 850px) 100vw, 850px"/> 

                                        </div>
                                        </div>
                                        <div class="elementor-element elementor-element-811bdd2 elementor-widget elementor-widget-image" data-id="811bdd2" data-element_type="widget" data-widget_type="image.default">
                                        <div class="elementor-widget-container">

                                        <img fetchpriority="high" decoding="async" width="850" height="700" src="images/cuadro_2.webp" class="elementor-animation-float attachment-large size-large wp-image-76" alt="" srcset="images/cuadro_2.webp 850w, images/cuadro_2.webp 300w, images/cuadro_2.webp 768w" sizes="(max-width: 850px) 100vw, 850px"/>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f879bcb" data-id="f879bcb" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-23e3e18 elementor-widget elementor-widget-image" data-id="23e3e18" data-element_type="widget" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                        <img decoding="async" width="700" height="850" src="images/cuadro_12.webp" class="elementor-animation-float attachment-large size-large wp-image-443" alt="" sizes="(max-width: 700px) 100vw, 700px"/>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </section>
                                        </div>
                                        </div>
                                        </div>

                                    </section>

                              </div>

                             </div>

                    </article>
                    </main>
                    </div>
                    </div> 
                    </div>

              </div>

        </div>
)
    }
  
}

export default Galery


