import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import HeaderBlog from '../../Conteiners/HeaderBlog'
import MetaReact from '../../Conteiners/MetaReact'
import SharedBlog from '../../Conteiners/ShareBlog'
import ViewMoreContent from '../../../services/ViewMoreContentService'
import * as DateController from '../../../Helpers/DateController'
import { ReactTinyLink } from 'react-tiny-link'

var utf8 = require('utf8');
var base64 = require('base-64');

export const Publishing = ({ publishing }) => {
  return (

    <div className="Content-st">

      <MetaReact
        title={publishing.state.publishing.content.title}
        img = "icon/ms-icon-144x144.png"
        desc={publishing.state.publishing.content.description}
        autor={publishing.state.publishing.content.autorName}
      />

      <div class="wrapper ">
        <HeaderBlog
          title={publishing.state.publishing.content.title}
          ImgUrl={publishing.state.publishing.content.imgUrl}
          date={publishing.state.publishing.content.createDate}
          autor={publishing.state.publishing.autorName}
        />

        <br/><br/><br/><br/>

        <div class="row">
          <div class="col-12 justify-content-center">
            <div class="container rounded mt-2">
              {ReactHtmlParser(
                utf8.decode(base64.decode(publishing.state.publishing.content.content))
              )}
             
              <p class="pl-4 pt-3 m-0 autor-published">
               
              </p>

              <div>
              <br/><br/>
              <img src={publishing.state.publishing.urlImgAutor} className="rounded-circle ml-4 mt-2" alt="Photo Profile" width="75" height="75"/>
              <br/><br/>
              <p className="pl-4 m-0 font-weight-bold autor-post pt-2">By {publishing.state.publishing.autorName}</p>
              <p className="pl-4 m-0 pt-1 autor-description">{publishing.state.publishing.aboutAutor}</p>
            </div>

              <p class="pl-4 m-0 pt-3">Share</p>

              <div class="conteiner pl-4 pt-1">
                <SharedBlog
                  title={publishing.state.publishing.content.title}
                  img={publishing.state.publishing.content.imgUrl}
                  desc={publishing.state.publishing.content.description}
                  autor={publishing.state.publishing.content.autorName}
                  id={publishing.state.publishing.content.id}
                  size={25}
                  rounded={true}
                />
              </div>
            </div>
          </div>

      
        </div>
        
        <div class="container py-1 text-left mt-5">
          <h3 class="mb-2 ml-4 pl-2"><span></span> You may also like</h3>
        </div>

        <ViewMoreContent notId={publishing.state.publishing.content.id} max={3} />

      </div>
    </div>
  )
};

export default Publishing;
